/* eslint react/no-did-mount-set-state: "off" */
/* eslint react/no-did-update-set-state: "off" */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import '../../styles/forms.css'
import { DateTime } from 'luxon'
import i18n from '../../i18n'

const config = {
  week_subs: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
  week_subs_spanish: ['DO', 'LU', 'MA', 'MI', 'JU', 'VI', 'SA']
}

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYearMonth: DateTime.now().startOf('month'),
    }
  }

  componentDidMount() {
    if (this.props.holidayList.length != 0) {
      let validDefaultDate = this.getValidDefaultSelected(this.props.selectedDate)
      this.props.onDateInitialized(validDefaultDate.toJSDate())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.holidayList !== this.props.holidayList) {
      let validDefaultDate = this.getValidDefaultSelected(this.props.selectedDate)
      this.props.onDateInitialized(validDefaultDate.toJSDate())
    }
  }

  updateMonth(numMonths) {
    this.setState({
      currentYearMonth: this.state.currentYearMonth.plus({ months: numMonths }),
    })
  }

  prev() {
    this.updateMonth(-1)
  }

  next() {
    this.updateMonth(1)
  }

  isTheWeekend(currentDate) {
    // Luxon uses 6 = Saturday, 7 = Sunday
    const isTheWeekend = currentDate.weekday > 5
    return isTheWeekend
  }

  isAHoliday(currentDate) {
    const dateList = this.props.holidayList.map(stringDate => DateTime.fromISO(stringDate))
    return dateList.filter(holiday => holiday.hasSame(currentDate, 'day')).length > 0
  }

  greaterThanSixtyDays(currentDate) {
    return currentDate > DateTime.now().startOf('day').plus({ days: 60 })
  }

  getValidDefaultSelected(startDate) {
    for (let i = 0; i < 50; i++) {
      let validDate = DateTime.fromJSDate(startDate).plus({ days: i })
      if (!this.isAHoliday(validDate) && !this.isTheWeekend(validDate)) {
        return validDate
      }
    }
  }

  onDatePicked(month, day) {
    const d = this.state.currentYearMonth.plus({ months: month }).set({ day })

    // Customer can't turn off their power in the past (this should be a component prop if we want to use this elsewhere)
    if (d.hasSame(DateTime.now(), 'day') || this.isTheWeekend(d) || this.isAHoliday(d) || this.greaterThanSixtyDays(d))
      return

    this.props.onDatePicked(d)
  }

  renderDay(opts = {}) {
    let baseClasses = "day noselect"
    let today = opts.today ? "today" : ""
    let selected = opts.selected ? "selected" : ""

    let date = DateTime.fromJSDate(opts.date)

    baseClasses += opts.current
      && date > DateTime.now()
      && !this.isTheWeekend(date)
      && !this.isAHoliday(date)
      && !this.greaterThanSixtyDays(date) ? "" : " non-current"

    let isDisabled = baseClasses === "day noselect non-current"

    return (
      <div className="day" key={opts.indexKey}>
        <IconButton
          className={`calendar-dates ${selected} ${today}`}
          size="small"
          disabled={isDisabled}
          onClick={() => this.onDatePicked(opts.month, date.day)}
        >
          {date.day}
        </IconButton>
      </div>
    )
  }

  renderDays(currentDate) {
    let days = [];
    // set to beginning of month
    currentDate.setDate(1);

    // if we are missing no offset, include the previous week
    let offset = currentDate.getDay() === 0 ? 7 : currentDate.getDay();

    currentDate.setDate(-offset);

    let inMonth = false;
    let lastMonth = true;

    for (let i = 0; i < 42; i++) {
      // increase date
      currentDate.setDate(currentDate.getDate() + 1);

      // make sure we pass any previous month values
      if (i < 30 && currentDate.getDate() === 1) {
        inMonth = true;
        lastMonth = false;
      }
      // if we are seeing the '1' again, we have iterated over
      // the current month
      else if (i > 30 && currentDate.getDate() === 1) {
        inMonth = false;
      }

      let sel = new Date(this.props.selectedDate)
      let isSelected = (sel.getFullYear() === currentDate.getFullYear()
        && sel.getDate() === currentDate.getDate()
        && sel.getMonth() === currentDate.getMonth()
        && inMonth
        && !this.isAHoliday(currentDate)
        && !this.isTheWeekend(currentDate)
        && !this.greaterThanSixtyDays(currentDate)
        && DateTime.fromJSDate(currentDate) > DateTime.now())

      let isToday = DateTime.fromJSDate(currentDate).hasSame(DateTime.now().setZone('MST'), 'day')

      days.push(this.renderDay({
        today: isToday,
        selected: isSelected,
        current: inMonth,
        month: (inMonth ? 0 : (lastMonth ? -1 : 1)),
        date: currentDate,
        indexKey: i
      }));
    }

    return days;
  }

  renderHeaders() {
    const header = [];
    let week_subs_array = i18n.language==="es" ? config.week_subs_spanish : config.week_subs;
    [...Array(week_subs_array.length).keys()].map(index => {
      header.push(<p className="day-headers noselect" key={index}>
        {week_subs_array[index]}
      </p>);
    })

    return header;
  }

  render() {
    const header = this.renderHeaders()
    const currentYearMonth = DateTime.fromISO(this.state.currentYearMonth)

    // get the month days
    const days = this.renderDays(currentYearMonth.toJSDate())

    const month = currentYearMonth.toFormat('MMMM')
    const year = currentYearMonth.year

    return (
      <div className="calendar">
        <div>
          <div className="month">
            <IconButton className="ml-2" onClick={this.prev.bind(this)} size="large">
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <p className="month-title">{month}<br />
              <span className="month-year">{year}</span>
            </p>
            <IconButton className="mr-2" onClick={this.next.bind(this)} size="large">
              <Icon>keyboard_arrow_right</Icon>
            </IconButton>
          </div>
          <div className="footer">
            {header}
            {days}
          </div>
        </div>
      </div>
    )
  }
}

Calendar.propTypes = {
  accentColor: PropTypes.string,
  onDatePicked: PropTypes.func,
  onDateInitialized: PropTypes.func.isRequired,
  holidayList: PropTypes.arrayOf(PropTypes.string),
  selectedDate: PropTypes.object
}

Calendar.defaultProps = {
  accentColor: '#00C1A6',
  onDatePicked: function () { },
}

export default Calendar