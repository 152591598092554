export const pricePlanConstants = {
    ACOUNT_TYPE: {
        RESIDENTIAL: 0,
        COMMERCIAL: 1,
        UNKNOWN: 2
    },

    PRICE_PLAN_RES_SERVER_MAP: {
        UNKNOWN: 0,
        EZ3: 1,
        EZ3_4_7: 2,
        TOU: 3,
        BASIC: 4,
        MPOWER: 5,
        EV: 6,
        GENERATION: 7,
        EZ3_2_5: 8,
        TOU_DEMAND: 9,
        // generation plans
        TOU_EXPORT: 10,
        EV_EXPORT: 11,
        AVERAGE_DEMAND: 12
    },

    PRICE_PLAN_COMM_SERVER_MAP: {
        UNKNOWN: 0,
        GENERAL: 1,
        TOU: 2
    },

    PRICE_PLAN_RATE_CODE_MAP: {
        UNKNOWN: 0,
        GENERATION: 1,
        EZ3: 6,
        EZ3_2_5: 7,
        EZ3_4_7: 2,
        TOU: 9,
        BASIC: 8,
        MPOWER: 38,
        EV: 10,
        COMM_TOU: 13,
        COMM_PEAK: 14,
        COMM_GENERAL: 18,
        TOU_EXPORT: 35,
        EV_EXPORT: 36,
        AVERAGE_DEMAND: 37
    },

    PRICE_PLAN_NAME: {
        EN: {
            UNKNOWN: "unknown",
            EZ3_RES: "EZ-3 Plan (3-6pm)",
            EZ3_2_5: "EZ-3 Plan (2-5pm)",
            EZ3_4_7: "EZ-3 Plan (4-7pm)",
            TOU_RES: "Time-of-Use Plan",
            BASIC_RES: "Basic Plan",
            MPOWER_RES: "M-Power Plan",
            EV_RES: "Electric Vehicle Plan",
            GENERATION_RES: "Customer Generation Plan",
            GENERAL_COMM: "Business General Service Plan",
            TOU_COMM: "Business Time-of-Use Plan",
            TOU_DEMAND: "Time-of-Use Demand Plan",
            TOU_EXPORT: "Time-of-Use Export Plan",
            EV_EXPORT: "Electric Vehicle Export Plan",
            AVERAGE_DEMAND: "Average Demand Plan"
        },
        ES: {
            UNKNOWN: "unknown",
            EZ3_RES: "Plan EZ-3 (3-6pm)",
            EZ3_2_5: "Plan EZ-3 (2-5pm)",
            EZ3_4_7: "Plan EZ-3 (4-7pm)",
            TOU_RES: "Plan Time-of-Use",
            BASIC_RES: "Plan Básico",
            MPOWER_RES: "Plan M-Power",
            EV_RES: "Plan Electric Vehicle",
            GENERATION_RES: "Plan Customer Generation",
            GENERAL_COMM: "Business General Service Plan",
            TOU_COMM: "Business Time-of-Use Plan",
            TOU_DEMAND: "Time-of-Use Demand Plan",
            TOU_EXPORT: "Time-of-Use Export Plan",
            EV_EXPORT: "Electric Vehicle Export Plan",
            AVERAGE_DEMAND: "Average Demand Plan"
        }
    },

    SOLAR_CHOICE: {
        NONE: 'None', //1
        SC100: 'SolarChoice100', //2
        SC50: 'SolarChoice50', //3
        UNENROLL: 'UnEnroll', //4
    },

    STEPS_ANON: [
        {
            step: 0,
            labelEn: "Account details",
            labelEs: "Detalles de la cuenta",
            titleEn: "Tell us about yourself",
            titleEs: "Háblanos de ti"
        },
        {
            step: 1,
            labelEn: "Select plan",
            labelEs: "Seleccionar plan",
            titleEn: "Let's choose your price plan",
            titleEs: "Elijamos tu plan de precios"
        },
        {
            step: 2,
            labelEn: "Review",
            labelEs: "Revisión",
            titleEn: "Let's make sure everything is right",
            titleEs: "Asegurémonos de que todo esté bien"
        }
    ],

    STEPS_AUTH: [
        {
            step: 0,
            labelEn: "Select plan",
            labelEs: "Seleccionar plan",
            titleEn: "Let's choose your price plan",
            titleEs: "Elijamos tu plan de precios"
        },
        {
            step: 1,
            labelEn: "Review",
            labelEs: "Revisión",
            titleEn: "Let's make sure everything is right",
            titleEs: "Asegurémonos de que todo esté bien"
        }
    ],

    SUBMIT_CHANGE_PRICE_PLAN_REQUEST: 'SUBMIT_CHANGE_PRICE_PLAN_REQUEST',
    SUBMIT_CHANGE_PRICE_PLAN_SUCCESS: 'SUBMIT_CHANGE_PRICE_PLAN_SUCCESS',
    SUBMIT_CHANGE_PRICE_PLAN_FAILURE: 'SUBMIT_CHANGE_PRICE_PLAN_FAILURE',
}