import React from "react"
import PropTypes from 'prop-types'
import PhoneItem from './phone-item'
import PhoneAdd from './phone-add'
import { contactConstants } from '../../../../constants/contact-constants'
import { myAccountConstants } from '../../../../constants/myaccount-constants'
import PhoneAlertsSubscribed from './phone-alerts-subscribed'
import Collapse from '../../../common/collapse'

let getPhoneSubcriptions = (phoneNumber, subscribedContacts) => {
    
    if (subscribedContacts.phones)  {
        let currentPhone = subscribedContacts.phones[phoneNumber]

        if (currentPhone) {
            return currentPhone.subscribedNotifications
        }
    }

    return []
}

class PhoneCard extends React.Component {
    constructor(props) {
        super(props)

        this.getStatusMessage = this.getStatusMessage.bind(this)
    }
    getStatusMessage(alertInfo, t) {
        let statusWord = t("SUCCESS")
        let actionWord = alertInfo.actionType === myAccountConstants.ACTION_TYPE.ADD ? "added" : "updated"
        // let statusMessage = `Your phone number has been ${actionWord}`
        let statusMessage = t("phone_has_been_added_or_updated", {actionWord : actionWord})
    
        switch(alertInfo.status) {
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS:
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.FAILURE:
                statusWord = t("FAILED")
                // statusMessage = `Your phone number has not been ${actionWord}`
                statusMessage = t("phone_has_not_been_added_or_updated", {actionWord : actionWord})
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.DUPLICATECONTACT:
                statusWord = t("FAILED")
                statusMessage = t("That phone number is already used")
                break
            case myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED:
                statusWord = ""
                // statusMessage = `You have reached the maximum number of phones (${myAccountConstants.MAX_CONTACT_COUNT}). Please modify or delete existing phones to proceed with changes`
                statusMessage = t("max_number_of_phones", {count: myAccountConstants.MAX_CONTACT_COUNT})
                break
            default: 
                statusWord = t("FAILED")
                statusMessage = t("Your request was not completed")
        }
    
        if (alertInfo.status === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.MAXCONTACTLIMITREACHED) {
            return <div>{statusMessage}</div>
        } else {
            return <div><span style={{fontWeight:"700"}}>{statusWord}</span>&nbsp;{statusMessage}</div>
        }
        
    }

    render() {
        return (
            <div>
                <label className="d-none d-lg-block h4 text-muted">{this.props.t("Phone")}</label>
                {this.props.alertInfo.show && this.props.alertInfo.contactType === contactConstants.CONTACT_TYPE.PHONE &&
                <div className={this.props.alertInfo.status === myAccountConstants.CUSTOMER_ACCOUNT_DATA_STATUS.SUCCESS ? "srp-alert-success" : "srp-alert-error"}>{this.getStatusMessage(this.props.alertInfo, this.props.t)}</div>}
                {this.props.phoneNumbers.length === 0 && 
                    <div style={{paddingTop: "10px"}}>
                        {this.props.t("There are no phone numbers being used for contact and alerts at this time.")}
                    </div>
                }
                {this.props.phoneNumbers.map((phone, index) => {
                    let phoneSubscriptions = getPhoneSubcriptions(phone.contactAddress, this.props.subscribedContacts)
                    return (
                            <div key={`${phone.contactAddress}_${index}`}>
                                <PhoneItem 
                                    billAccount={this.props.billAccount}
                                    phone={phone} 
                                    updateContactClick={this.props.updateContactClick}
                                    makePrimaryClick={this.props.makePrimaryClick}
                                    updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                    deleteContactClick={this.props.deleteContactClick}
                                    hideAlert={this.props.hideAlert}
                                    openItem={this.props.openItem}
                                    setOpenItem={this.props.setOpenItem}
                                    t={this.props.t}
                                />
                                {phoneSubscriptions && phoneSubscriptions.length > 0 && 
                                <Collapse 
                                    title={this.props.t("Enrolled and Pending Alerts")}
                                    collapsed={true}>
                                    <PhoneAlertsSubscribed
                                        phone={phone.contactAddress}
                                        subscriptions={phoneSubscriptions}
                                        t={this.props.t}
                                />      
                                </Collapse>
                                }
                            </div>
                            
                        )
                    })
                }
                <div>
                    <PhoneAdd
                        subscribedContacts={this.props.subscribedContacts}
                        addContactsClick={this.props.addContactsClick}
                        addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                        hideAlert={this.props.hideAlert}
                        openItem={this.props.openItem}
                        setOpenItem={this.props.setOpenItem}
                        alertInfo={this.props.alertInfo}
                        t={this.props.t}
                     />
                </div>

            </div>
        )
    }
}

PhoneCard.propTypes = {
    billAccount: PropTypes.number.isRequired,
    phoneNumbers: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateContactClick: PropTypes.func.isRequired,
    makePrimaryClick: PropTypes.func.isRequired,
    updateContactAndMakePrimaryClick: PropTypes.func.isRequired,
    addContactsClick: PropTypes.func.isRequired,
    addContactsAndCopySubscriptionsClick: PropTypes.func.isRequired,
    deleteContactClick: PropTypes.func.isRequired,
    subscribedContacts: PropTypes.object.isRequired,
    alertInfo: PropTypes.object.isRequired,
    hideAlert: PropTypes.func.isRequired,
    openItem: PropTypes.string,
    setOpenItem: PropTypes.func.isRequired,
    t:  PropTypes.func
}

export default PhoneCard