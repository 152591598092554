import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import format from '../../../srp_modules/format'
import formikInputFilter from '../../../srp_modules/formik-input-filter'
import AddressWithAutoComplete from '../../address-with-autocomplete'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

import '../../../styles/price-plan.css'

class ServiceAndBillingInfo extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Formik
                enableReinitialize={false}
                initialValues={{
                    serviceAddressOne: this.props.serviceBillingInfo.serviceAddressOne,
                    serviceAddressTwo: this.props.serviceBillingInfo.serviceAddressTwo,
                    serviceCity: this.props.serviceBillingInfo.serviceCity,
                    serviceAddIsMailingAdd: this.props.serviceBillingInfo.serviceAddIsMailingAdd,
                    mailingAddressOne: this.props.serviceBillingInfo.mailingAddressOne,
                    mailingAddressTwo: this.props.serviceBillingInfo.mailingAddressTwo,
                    mailingCity: this.props.serviceBillingInfo.mailingCity,
                    mailingState: this.props.serviceBillingInfo.mailingState,
                    mailingCountry: this.props.serviceBillingInfo.mailingCountry,
                    mailingZipCode: this.props.serviceBillingInfo.mailingZipCode,
                    maintainAutopay: this.props.currentAutoPayEnrollment.isEnrolled
                        ? this.props.serviceBillingInfo.maintainAutopay
                        : false,
                    lastFourOfAutopayBank: this.props.currentAutoPayEnrollment.isEnrolled
                        ? this.props.lastFourAutopayBank
                        : "",
                    maintainCustomDueDate: this.props.currentCustomDueDateEnrollment.isEnrolled
                        ? this.props.serviceBillingInfo.maintainCustomDueDate
                        : false,
                    customDueDate: this.props.serviceBillingInfo.maintainCustomDueDate
                        ? 0
                        : this.props.serviceBillingInfo.customDueDate,
                    maintainPaperlessBill: this.props.hasEBill
                        ? this.props.serviceBillingInfo.maintainPaperlessBill
                        : false
                }}
                validate={(values) => validateForm(values, this.props.currentCustomDueDateEnrollment.isEnrolled, this.props.setServiceAndBillingInfoOnChange, this.props.t)}
                onSubmit={(values) => {
                    let updatedValues = {
                        ...values,
                        customDueDate: values.maintainCustomDueDate ? this.props.currentCustomDueDateEnrollment.optionValue : values.customDueDate
                    }
                    let transformedValues = transformValues(updatedValues)
                    this.props.setServiceAndBillingInfoOnChange(transformedValues)
                    this.props.setFormStep(this.props.activeStep + 1)
                }}>
                {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => {
                    let serviceAddressError = touched.serviceAddressOne && errors.serviceAddress !== undefined
                    let serviceCityError = touched.serviceCity && errors.serviceCity !== undefined
                    let mailingAddressError = touched.mailingAddressOne && errors.mailingAddress !== undefined
                    let mailingCityError = touched.mailingCity && errors.mailingCity !== undefined
                    let mailingStateError = touched.mailingState && errors.mailingState !== undefined
                    let mailingZipCodeError = touched.mailingZipCode && errors.mailingZipCode !== undefined
                    let customDueDateError = touched.customDueDate && errors.customDueDate !== undefined

                    let setServiceAddressComponents = (components) => {
                        setFieldValue('serviceAddressOne', `${components.streetNumber} ${components.street}`, true)
                        setFieldValue('serviceCity', components.city, true)
                    }

                    let setMailingAddressComponents = (components) => {
                        formikInputFilter.filterStreetAddress(`${components.streetNumber} ${components.street}`, 'mailingAddressOne', setFieldValue)
                        formikInputFilter.filterStreetAddress(components.city, 'mailingCity', setFieldValue)
                        setMailingStateOnChange(components.state)
                        formikInputFilter.filterStreetAddress(components.country, 'mailingCountry', setFieldValue)
                        formikInputFilter.filterStreetAddress(components.zipCode, 'mailingZipCode', setFieldValue)
                    }

                    let onMailAddressCheck = value => {
                        setFieldValue('serviceAddIsMailingAdd', value, true)
                        setFieldTouched('mailingAddressOne', false)
                        setFieldTouched('mailingAddressTwo', false)
                        setFieldTouched('mailingCity', false)
                        setFieldTouched('mailingState', false)
                        setFieldTouched('mailingCountry', false)
                        setFieldTouched('mailingZipCode', false)
                    }

                    let onMaintainAutopayCheck = value => {
                        setFieldValue('maintainAutopay', value, true)
                    }

                    let onMaintainCustomDueDateCheck = value => {
                        setFieldValue('maintainCustomDueDate', value, true)
                    }

                    let selectCustomDueDate = value => {
                        setFieldValue('customDueDate', value, true)
                    }

                    let onMaintainPaperlessBillCheck = value => {
                        setFieldValue('maintainPaperlessBill', value, true)
                    }

                    let setMailingStateOnChange = state => {
                        if (state.match(formikInputFilter.regex.alphaRegex)) {
                            setFieldValue('mailingState', state, true)
                        }
                    }

                    return (
                        <form
                            className="mb-2 mt-2 text-muted"
                            onSubmit={handleSubmit}
                        >
                            <div className="mb-3">
                                <span>{`${this.props.t("your_turning_off_electric")} `}</span>
                                <span><strong>{format.formatBillAccountNumber(this.props.billAccount)}</strong></span>
                                <span>{` ${this.props.t("as of")} `}</span>
                                <span><strong>{DateTime.fromISO(this.props.endServiceDate).toFormat('M/d/yyyy')}</strong></span>.
                            </div>

                            <div className="mb-3">
                                <div>{this.props.customerNames.firstName} {this.props.customerNames.middleName} {this.props.customerNames.lastName}</div>
                                <div className="text-capitalize">{(this.props.serviceAddress.streetAddress).toLowerCase()}</div>
                                <div>
                                    <span className="text-capitalize">{`${(this.props.serviceAddress.cityName).toLowerCase()}, `}</span>
                                    <span>{`${this.props.serviceAddress.state} `}</span>
                                    <span>{`${this.props.serviceAddress.zipCode}`}</span>
                                </div>
                            </div>

                            <div className="mb-3 section-header">
                                {`${this.props.t("Where are you moving to on")} ${DateTime.fromISO(this.props.startServiceDate).toFormat('M/d/yyyy')}?`}
                            </div>
                            <AddressWithAutoComplete
                                fieldName="serviceAddressOne"
                                fieldValue={values.serviceAddressOne}
                                handleAddressChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'serviceAddressOne', setFieldValue)}
                                handleAddressBlur={handleBlur}
                                errorText={serviceAddressError ? this.props.t(errors.serviceAddress) : ''}
                                hasError={serviceAddressError}
                                updateAddressCallback={setServiceAddressComponents}
                                isDomesticAddress={true} 
                                label={this.props.t("Street address")}
                            />
                            <TextField
                                fullWidth
                                className="mb-3"
                                name="serviceAddressTwo"
                                inputProps={{ maxLength: 39 }}
                                value={values.serviceAddressTwo}
                                onChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'serviceAddressTwo', setFieldValue)}
                                onBlur={handleBlur}
                                label={this.props.t("Apt, suite, etc (optional)")}
                            />
                            <TextField
                                fullWidth
                                className="mb-3"
                                name="serviceCity"
                                inputProps={{ maxLength: 30 }}
                                value={values.serviceCity}
                                onChange={e => formikInputFilter.filterStreetAddress(e.target.value, 'serviceCity', setFieldValue)}
                                onBlur={handleBlur}
                                helperText={serviceCityError ? this.props.t(errors.serviceCity) : ''}
                                error={serviceCityError}
                                label={this.props.t("City")}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    name="serviceAddIsMailingAdd"
                                    checked={values.serviceAddIsMailingAdd}
                                    onChange={e => onMailAddressCheck(e.target.checked)}
                                    color="primary"
                                />}
                                label={
                                    <span className="font-weight-bold" style={{ color: 'rgb(72, 72, 74)' }}>
                                        {this.props.t("This service address is also my mailing address")}
                                    </span>
                                }
                            />
                            {!values.serviceAddIsMailingAdd &&
                                <div>
                                    <AddressWithAutoComplete
                                        fieldName="mailingAddressOne"
                                        fieldValue={values.mailingAddressOne}
                                        handleAddressChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'mailingAddressOne', setFieldValue)}
                                        handleAddressBlur={handleBlur}
                                        errorText={mailingAddressError ? this.props.t(errors.mailingAddress) : ''}
                                        hasError={mailingAddressError}
                                        updateAddressCallback={setMailingAddressComponents}
                                        isDomesticAddress={false} 
                                        label={this.props.t("Street address")}
                                    />
                                    <TextField
                                        fullWidth
                                        className="mb-3"
                                        name="mailingAddressTwo"
                                        inputProps={{ maxLength: 39 }}
                                        value={values.mailingAddressTwo}
                                        onChange={e => formikInputFilter.filterStreetAddress(e.target.value, 'mailingAddressTwo', setFieldValue)}
                                        onBlur={handleBlur}
                                        label={this.props.t("Apt, suite, etc (optional)")}
                                    />
                                    <TextField
                                        fullWidth
                                        className="mb-3"
                                        name="mailingCity"
                                        inputProps={{ maxLength: 30 }}
                                        value={values.mailingCity}
                                        onChange={e => formikInputFilter.filterStreetAddress(e.target.value, 'mailingCity', setFieldValue)}
                                        onBlur={handleBlur}
                                        helperText={mailingCityError ? this.props.t(errors.mailingCity) : ''}
                                        error={mailingCityError}
                                        label={this.props.t("City")}
                                    />
                                    <TextField
                                        fullWidth
                                        className="mb-3"
                                        name="mailingState"
                                        inputProps={{ maxLength: 2 }}
                                        value={values.mailingState}
                                        onChange={(e) => setMailingStateOnChange(e.target.value)}
                                        onBlur={handleBlur}
                                        helperText={mailingStateError ? this.props.t(errors.mailingState) : ''}
                                        error={mailingStateError}
                                        label={this.props.t("State")}
                                    />
                                    <TextField
                                        fullWidth
                                        className="mb-3"
                                        name="mailingCountry"
                                        inputProps={{ maxLength: 25 }}
                                        value={values.mailingCountry}
                                        onChange={e => formikInputFilter.filterStreetAddress(e.target.value, 'mailingCountry', setFieldValue)}
                                        onBlur={handleBlur}
                                        label={`${this.props.t("Country")} (${this.props.t("optional")})`}
                                    />
                                    <TextField
                                        name="mailingZipCode"
                                        className="mb-3"
                                        inputProps={{ maxLength: 9 }}
                                        value={values.mailingZipCode}
                                        fullWidth={true}
                                        onChange={(e) => formikInputFilter.filterStreetAddress(e.target.value, 'mailingZipCode', setFieldValue)}
                                        onBlur={() => setFieldTouched("mailingZipCode", true)}
                                        helperText={mailingZipCodeError ? this.props.t(errors.mailingZipCode) : ''}
                                        error={mailingZipCodeError}
                                        label={this.props.t("Zip code")}
                                    />
                                </div>
                            }

                            {(this.props.currentAutoPayEnrollment.isEnrolled
                                || this.props.currentCustomDueDateEnrollment.isEnrolled
                                || this.props.hasEBill)
                                && !this.props.isMPower &&
                                <div className="mt-3 mb-3">
                                    <div className="mt-3 mb-2 section-header">
                                        {this.props.t("Options for your bill")}
                                    </div>

                                    {this.props.currentAutoPayEnrollment.isEnrolled &&
                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    name="maintainAutopay"
                                                    checked={values.maintainAutopay}
                                                    onChange={e => onMaintainAutopayCheck(e.target.checked)}
                                                    color="primary"
                                                />}
                                                label={<div className="mb-0">{this.props.t("Maintain my SurePay agreement")}</div>}
                                            />
                                        </div>
                                    }
                                    {this.props.currentCustomDueDateEnrollment.isEnrolled &&
                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    name="maintainCustomDueDate"
                                                    checked={values.maintainCustomDueDate}
                                                    onChange={e => onMaintainCustomDueDateCheck(e.target.checked)}
                                                    color="primary"
                                                />}
                                                label={<div className="mb-0">
                                                    <span>{`${this.props.t("Maintain my Custom Due Date")} `}</span>
                                                    <span className="text-muted">
                                                        {`(${this.props.t("currently the")} ${formatDayWithOrdinal(this.props.currentCustomDueDateEnrollment.optionValue)} ${this.props.t("of each month")})`}
                                                    </span>
                                                </div>}
                                            />
                                            {!values.maintainCustomDueDate &&
                                                <div className="pb-3">
                                                    <div>
                                                        <TextField
                                                            select
                                                            name="customDueDate"
                                                            value={values.customDueDate}
                                                            onChange={(e) => selectCustomDueDate(e.target.value)}
                                                            onBlur={e => setFieldTouched(e.target.name)}
                                                            error={customDueDateError}
                                                            label={this.props.t("Select new Custom Due Date")}
                                                            sx={{minWidth : '170px'}}
                                                        >
                                                            <MenuItem value={0}>{this.props.t("Day")}</MenuItem>
                                                            <MenuItem value={1}>1st</MenuItem>
                                                            <MenuItem value={2}>2nd</MenuItem>
                                                            <MenuItem value={3}>3rd</MenuItem>
                                                            <MenuItem value={4}>4th</MenuItem>
                                                            <MenuItem value={5}>5th</MenuItem>
                                                            <MenuItem value={6}>6th</MenuItem>
                                                            <MenuItem value={7}>7th</MenuItem>
                                                            <MenuItem value={8}>8th</MenuItem>
                                                            <MenuItem value={9}>9th</MenuItem>
                                                            <MenuItem value={10}>10th</MenuItem>
                                                            <MenuItem value={11}>11th</MenuItem>
                                                            <MenuItem value={12}>12th</MenuItem>
                                                            <MenuItem value={13}>13th</MenuItem>
                                                            <MenuItem value={14}>14th</MenuItem>
                                                            <MenuItem value={15}>15th</MenuItem>
                                                            <MenuItem value={16}>16th</MenuItem>
                                                            <MenuItem value={17}>17th</MenuItem>
                                                            <MenuItem value={18}>18th</MenuItem>
                                                            <MenuItem value={19}>19th</MenuItem>
                                                            <MenuItem value={20}>20th</MenuItem>
                                                            <MenuItem value={21}>21st</MenuItem>
                                                            <MenuItem value={22}>22nd</MenuItem>
                                                            <MenuItem value={23}>23rd</MenuItem>
                                                            <MenuItem value={24}>24th</MenuItem>
                                                            <MenuItem value={25}>25th</MenuItem>
                                                            <MenuItem value={26}>26th</MenuItem>
                                                            <MenuItem value={27}>27th</MenuItem>
                                                            <MenuItem value={28}>28th</MenuItem>
                                                        </TextField>
                                                        <span className="ml-3 text-dark">{this.props.t("of the month")}</span>
                                                    </div>
                                                    {customDueDateError &&
                                                        <FormHelperText error={customDueDateError}>
                                                            {this.props.t("Custom Due Date is required")}
                                                        </FormHelperText>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    {this.props.hasEBill &&
                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    name="maintainPaperlessBill"
                                                    checked={values.maintainPaperlessBill}
                                                    onChange={e => onMaintainPaperlessBillCheck(e.target.checked)}
                                                    color="primary"
                                                />}
                                                label={<div className="mb-0">{this.props.t("Maintain my paperless billing enrollment")}</div>}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            <div>
                                <span>{`${this.props.t("All other programs on your current account")} `}</span>
                                <span className="font-weight-bold">{`${this.props.t("will transfer")} `}</span>
                                <span>{`${this.props.t("to your new account")}.`}</span>
                            </div>

                            <div className="d-flex flex-wrap justify-content-end mt-4">
                                <button
                                    className="btn srp-btn btn-lightblue ml-2"
                                    type="button"
                                    onClick={() => this.props.setFormStep(this.props.activeStep - 1)}>
                                    {this.props.t("Previous")}
                                </button>
                                <button className="btn srp-btn btn-blue ml-2" type="submit">{this.props.t("Next")}</button>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

ServiceAndBillingInfo.propTypes = {
    activeStep: PropTypes.number,
    setFormStep: PropTypes.func,

    billAccount: PropTypes.number,
    isMPower: PropTypes.bool,
    customerNames: PropTypes.object,
    serviceAddress: PropTypes.object,

    endServiceDate: PropTypes.object,
    startServiceDate: PropTypes.object,

    serviceBillingInfo: PropTypes.object,
    setServiceAndBillingInfoOnChange: PropTypes.func,

    currentAutoPayEnrollment: PropTypes.object,
    lastFourAutopayBank: PropTypes.string,
    currentCustomDueDateEnrollment: PropTypes.object,
    hasEBill: PropTypes.bool,

    t: PropTypes.func
}

export default withTranslation('transferService')(ServiceAndBillingInfo)

function transformValues(values) {
    return {
        ...values,
        serviceAddressOne: values.serviceAddressOne.trim(),
        serviceAddressTwo: values.serviceAddressTwo.trim(),
        serviceCity: values.serviceCity.trim(),
        mailingAddressOne: values.mailingAddressOne.trim(),
        mailingAddressTwo: values.mailingAddressTwo.trim(),
        mailingCity: values.mailingCity.trim(),
        mailingState: values.mailingState.trim(),
        mailingCountry: values.mailingCountry.trim(),
        mailingZipCode: values.mailingZipCode.trim()
    }
}

function validateForm(values, isCDD, setValues) {
    let errors = {}

    if (values.serviceAddressOne === undefined || values.serviceAddressOne.trim().length === 0)
        errors.serviceAddress = "Address is required"

    if (values.serviceCity === undefined || values.serviceCity.trim().length === 0)
        errors.serviceCity = "City is required"

    if (!values.serviceAddIsMailingAdd) {
        if (values.mailingAddressOne === undefined || values.mailingAddressOne.trim().length === 0)
            errors.mailingAddress = "Mailing address is required"

        if (values.mailingCity === undefined || values.mailingCity.trim().length === 0)
            errors.mailingCity = "Mailing address is required"

        if (values.mailingState === undefined || values.mailingState.trim().length === 0)
            errors.mailingState = "Mailing address is required"

        if (values.mailingZipCode === undefined || values.mailingZipCode.trim().length === 0)
            errors.mailingZipCode = "Mailing address is required"
    }

    if (!values.maintainCustomDueDate && isCDD) {
        if (values.customDueDate === undefined || values.customDueDate <= 0)
            errors.customDueDate = "Custom Due Date is required"
    }

    let serviceBillingInfo = {
        serviceAddressOne: values.serviceAddressOne,
        serviceAddressTwo: values.serviceAddressTwo,
        serviceCity: values.serviceCity,
        serviceAddIsMailingAdd: values.serviceAddIsMailingAdd,
        mailingAddressOne: values.mailingAddressOne,
        mailingAddressTwo: values.mailingAddressTwo,
        mailingCity: values.mailingCity,
        mailingState: values.mailingState,
        mailingCountry: values.mailingCountry,
        mailingZipCode: values.mailingZipCode,
        maintainAutopay: values.maintainAutopay,
        lastFourOfAutopayBank: values.lastFourOfAutopayBank,
        maintainCustomDueDate: values.maintainCustomDueDate,
        customDueDate: values.customDueDate,
        maintainPaperlessBill: values.maintainPaperlessBill,
    }

    setValues(serviceBillingInfo)

    return errors
}
