import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import {
    setElectricVehicleMake,
    setElectricVehicleModel,
    setElectricVehicleYear,
    validateElectricVehicleYear,
    selectElectricVehicleOwnership,
    selectElectricVehicleEquipmentType,
    selectElectricVehicleInputVoltage,
    selectElectricVehicleInputCurrent
} from '../../../actions/auth/transfer_service/transfer-service-actions'

import { formConstants } from '../../../constants/form-constants'

class ElectricVehicleInfo extends React.Component {
    constructor(props) {
        super(props)

        this.trackSelectElectricVehicleOwnershipOnClick = this.trackSelectElectricVehicleOwnershipOnClick.bind(this)
        this.trackSelectElectricVehicleEquipmentTypeOnClick = this.trackSelectElectricVehicleEquipmentTypeOnClick.bind(this)
        this.trackSelectElectricVehicleInputVoltageOnClick = this.trackSelectElectricVehicleInputVoltageOnClick.bind(this)
        this.trackSelectElectricVehicleInputCurrentOnClick = this.trackSelectElectricVehicleInputCurrentOnClick.bind(this)
    }

    trackSelectElectricVehicleOwnershipOnClick(ownership) {
        this.props.selectElectricVehicleOwnershipOnClick(ownership)
        this.props.emitFormStartEvent()
    }

    trackSelectElectricVehicleEquipmentTypeOnClick(supplyEquipmentType) {
        this.props.selectElectricVehicleEquipmentTypeOnClick(supplyEquipmentType)
        this.props.emitFormStartEvent()
    }

    trackSelectElectricVehicleInputVoltageOnClick(voltage) {
        this.props.selectElectricVehicleInputVoltageOnClick(voltage)
        this.props.emitFormStartEvent()
    }

    trackSelectElectricVehicleInputCurrentOnClick(current) {
        this.props.selectElectricVehicleInputCurrentOnClick(current)
        this.props.emitFormStartEvent()
    }

    render() {
        const emitFormStartEvent = this.props.emitFormStartEvent

        return (
            <div className="mb-2">
                <div className="mb-3 section-header">{this.props.t("Electric vehicle details")}</div>
                <TextField
                    fullWidth
                    className="mb-3"
                    inputProps={{ maxLength: 30 }}
                    value={this.props.electricVehicleMake}
                    onChange={(e) => { this.props.setElectricVehicleMakeOnChange(e.target.value); emitFormStartEvent() }}
                    label={this.props.t("Make")}
                />
                <TextField
                    fullWidth
                    className="mb-3"
                    inputProps={{ maxLength: 50 }}
                    value={this.props.electricVehicleModel}
                    onChange={(e) => { this.props.setElectricVehicleModelOnChange(e.target.value); emitFormStartEvent() }}
                    label={this.props.t("Model")}
                />
                <PatternFormat
                    className="mb-3"
                    value={this.props.electricVehicleYear}
                    valueIsNumericString={true}
                    fullWidth={true}
                    type="tel"
                    onValueChange={(values) => { this.props.setElectricVehicleYearOnChange(values.value); emitFormStartEvent() }}
                    onBlur={() => this.props.validateElectricVehicleYearOnBlur(this.props.electricVehicleYear)}
                    format="####"
                    customInput={TextField}
                    error={this.props.evYearError}
                    helperText={this.props.t(this.props.evYearErrorMessage)}
                    label={this.props.t("Year")}
                />
                <div className="d-lg-block d-none">
                    <InputLabel>{("Do you own or lease this vehicle?")}</InputLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evOwnershipType === formConstants.VEHICLE_OWN}
                                onChange={e => this.trackSelectElectricVehicleOwnershipOnClick(e.target.value)}
                                value={formConstants.VEHICLE_OWN}
                                color="primary"
                                name={formConstants.VEHICLE_OWN}
                            />}
                            label={this.props.t("Own")}
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evOwnershipType === formConstants.VEHICLE_LEASE}
                                onChange={e => this.trackSelectElectricVehicleOwnershipOnClick(e.target.value)}
                                value={formConstants.VEHICLE_LEASE}
                                name={formConstants.VEHICLE_LEASE}
                                color="primary"
                            />}
                            label={this.props.t("Lease")}
                        />
                    </FormGroup>
                    <InputLabel>{this.props.t("Supply equipment type?")}</InputLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evEquipmentType === formConstants.SUPPLY_TYPE_1}
                                onChange={e => this.trackSelectElectricVehicleEquipmentTypeOnClick(e.target.value)}
                                value={formConstants.SUPPLY_TYPE_1}
                                color="primary"
                                name={formConstants.SUPPLY_TYPE_1}
                            />}
                            label={this.props.t("Type 1")}
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evEquipmentType === formConstants.SUPPLY_TYPE_2}
                                onChange={e => this.trackSelectElectricVehicleEquipmentTypeOnClick(e.target.value)}
                                value={formConstants.SUPPLY_TYPE_2}
                                color="primary"
                                name={formConstants.SUPPLY_TYPE_2}
                            />}
                            label={this.props.t("Type 2")}
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evEquipmentType === formConstants.SUPPLY_DONT_KNOW}
                                onChange={e => this.trackSelectElectricVehicleEquipmentTypeOnClick(e.target.value)}
                                value={formConstants.SUPPLY_DONT_KNOW}
                                color="primary"
                                name={formConstants.SUPPLY_DONT_KNOW}
                            />}
                            label={this.props.t("I don't know")}
                        />
                    </FormGroup>
                    <InputLabel>{this.props.t("Input voltage?")}</InputLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputVoltage === formConstants.INPUT_VOLTAGE_120}
                                onChange={e => this.trackSelectElectricVehicleInputVoltageOnClick(e.target.value)}
                                value={formConstants.INPUT_VOLTAGE_120}
                                color="primary"
                                name={formConstants.INPUT_VOLTAGE_120}
                            />}
                            label="120V"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputVoltage === formConstants.INPUT_VOLTAGE_220}
                                onChange={e => this.trackSelectElectricVehicleInputVoltageOnClick(e.target.value)}
                                value={formConstants.INPUT_VOLTAGE_220}
                                color="primary"
                                name={formConstants.INPUT_VOLTAGE_220}
                            />}
                            label="220V"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputVoltage === formConstants.VOLTAGE_DONT_KNOW}
                                onChange={e => this.trackSelectElectricVehicleInputVoltageOnClick(e.target.value)}
                                value={formConstants.VOLTAGE_DONT_KNOW}
                                color="primary"
                                name={formConstants.VOLTAGE_DONT_KNOW}
                            />}
                            label={this.props.t("I don't know")}
                        />
                    </FormGroup>
                    <InputLabel>{this.props.t("Input current?")}</InputLabel>
                    <FormGroup row>
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputCurrent === formConstants.AMPS_15}
                                onChange={e => this.trackSelectElectricVehicleInputCurrentOnClick(e.target.value)}
                                value={formConstants.AMPS_15}
                                color="primary"
                                name={formConstants.AMPS_15}
                            />}
                            label="15 amps"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputCurrent === formConstants.AMPS_20}
                                onChange={e => this.trackSelectElectricVehicleInputCurrentOnClick(e.target.value)}
                                value={formConstants.AMPS_20}
                                color="primary"
                                name={formConstants.AMPS_20}
                            />}
                            label="20 amps"
                        />
                        <FormControlLabel
                            control={<Radio
                                checked={this.props.evInputCurrent === formConstants.AMPS_DONT_KNOW}
                                onChange={e => this.trackSelectElectricVehicleInputCurrentOnClick(e.target.value)}
                                value={formConstants.AMPS_DONT_KNOW}
                                color="primary"
                                name={formConstants.AMPS_DONT_KNOW}
                            />}
                            label={this.props.t("I don't know")}
                        />
                    </FormGroup>
                </div>
                <div className="d-lg-none d-block">
                    <div>
                        <TextField
                            select
                            value={this.props.evOwnershipType}
                            onChange={e => this.trackSelectElectricVehicleOwnershipOnClick(e.target.value)}
                            className="mb-3"
                            fullWidth
                            label={this.props.t("Do you own or lease this vehicle?")}
                        >
                            <MenuItem value={formConstants.VEHICLE_OWN}>{this.props.t("Own")}</MenuItem>
                            <MenuItem value={formConstants.VEHICLE_LEASE}>{this.props.t("Lease")}</MenuItem>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            select
                            value={this.props.evEquipmentType}
                            onChange={e => this.trackSelectElectricVehicleEquipmentTypeOnClick(e.target.value)}
                            className="mb-3"
                            fullWidth
                            label={this.props.t("Supply equipment type?")}
                        >
                            <MenuItem value={formConstants.SUPPLY_TYPE_1}>{this.props.t("Type 1")}</MenuItem>
                            <MenuItem value={formConstants.SUPPLY_TYPE_2}>{this.props.t("Type 2")}</MenuItem>
                            <MenuItem value={formConstants.SUPPLY_DONT_KNOW}>{this.props.t("I don't know")}</MenuItem>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            select
                            value={this.props.evInputVoltage}
                            onChange={e => this.trackSelectElectricVehicleInputVoltageOnClick(e.target.value)}
                            className="mb-3"
                            fullWidth
                            label={this.props.t("Input voltage?")}
                        >
                            <MenuItem value={formConstants.INPUT_VOLTAGE_120}>120V</MenuItem>
                            <MenuItem value={formConstants.INPUT_VOLTAGE_220}>220V</MenuItem>
                            <MenuItem value={formConstants.VOLTAGE_DONT_KNOW}>{this.props.t("I don't know")}</MenuItem>
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            select
                            value={this.props.evInputCurrent}
                            onChange={e => this.trackSelectElectricVehicleInputCurrentOnClick(e.target.value)}
                            className="mb-3"
                            fullWidth
                            label={this.props.t("Input current?")}
                        >
                            <MenuItem value={formConstants.AMPS_15}>15 amps</MenuItem>
                            <MenuItem value={formConstants.AMPS_20}>20 amps</MenuItem>
                            <MenuItem value={formConstants.AMPS_DONT_KNOW}>{this.props.t("I don't know")}</MenuItem>
                        </TextField>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state.transferService }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setElectricVehicleMakeOnChange: (make) => {
            dispatch(setElectricVehicleMake(make))
        },
        setElectricVehicleModelOnChange: (model) => {
            dispatch(setElectricVehicleModel(model))
        },
        setElectricVehicleYearOnChange: (year) => {
            dispatch(setElectricVehicleYear(year))
        },
        validateElectricVehicleYearOnBlur: (year) => {
            dispatch(validateElectricVehicleYear(year))
        },
        selectElectricVehicleOwnershipOnClick: (ownership) => {
            dispatch(selectElectricVehicleOwnership(ownership))
        },
        selectElectricVehicleEquipmentTypeOnClick: (type) => {
            dispatch(selectElectricVehicleEquipmentType(type))
        },
        selectElectricVehicleInputVoltageOnClick: (voltage) => {
            dispatch(selectElectricVehicleInputVoltage(voltage))
        },
        selectElectricVehicleInputCurrentOnClick: (current) => {
            dispatch(selectElectricVehicleInputCurrent(current))
        },
    }
}

ElectricVehicleInfo.propTypes = {
    electricVehicleMake: PropTypes.string,
    setElectricVehicleMakeOnChange: PropTypes.func,

    electricVehicleModel: PropTypes.string,
    setElectricVehicleModelOnChange: PropTypes.func,

    electricVehicleYear: PropTypes.string,
    setElectricVehicleYearOnChange: PropTypes.func,
    evYearError: PropTypes.bool,
    evYearErrorMessage: PropTypes.string,
    validateElectricVehicleYearOnBlur: PropTypes.func,

    evOwnershipType: PropTypes.string,
    selectElectricVehicleOwnershipOnClick: PropTypes.func,

    evEquipmentType: PropTypes.string,
    selectElectricVehicleEquipmentTypeOnClick: PropTypes.func,

    evInputVoltage: PropTypes.string,
    selectElectricVehicleInputVoltageOnClick: PropTypes.func,

    evInputCurrent: PropTypes.string,
    selectElectricVehicleInputCurrentOnClick: PropTypes.func,

    emitFormStartEvent: PropTypes.func.isRequired,

    t: PropTypes.func
}

export default withTranslation('transferService')(connect(mapStateToProps, mapDispatchToProps)(ElectricVehicleInfo))