import { combineReducers } from 'redux'

import accountInfoReducer from './auth/account-info-reducer'
import landlordReducer from './auth/landlord/landlord-reducer'
import loginReducer from './auth/login/login-reducer'
import navigationReducer from './auth/navigation/navigation-reducer'
import pageHeaderReducer from './auth/navigation/page-header-reducer'
import myAccountReducer from './auth/myaccount/myaccount-reducer'
import dashboardReducer from './auth/dashboard/index'
import dailyUsageReducer from './auth/usage/daily-usage-reducer'
import hourlyUsageReducer from './auth/usage/hourly-usage-reducer'
import rateMetaDataReducer from './auth/usage/rate-meta-data-reducer'
import billProjectionReducer from './auth/payment/bill-projection-reducer'
import bankAccountsReducer from './auth/payment/bank-accounts-reducer'
import multiPaymentReducer from './auth/payment/multi_payment/multi-payment-reducer'
import multiPaymentNgpReducer from './auth/payment/multi_payment/multi-payment-ngp-reducer'
import accountHistoryReducer from './auth/payment/account-history-reducer'
import dailyWeatherDataReducer from './auth/weather/daily-weather-reducer'
import paymentLocationReducer from './guest/payment-location-reducer'
import prepayHistoryReducer from './auth/payment/prepay-history-reducer'
import guestCreditCardLinkReducer from './guest/payment/guest-credit-card-link-reducer'
import guestPaymentBankInfoReducer from './guest/payment/guest-payment-bank-info-reducer'
import guestPaymentListReducer from './guest/payment/guest-payment-list-reducer'
import guestPaymentApiResponseReducer from './guest/payment/guest-payment-api-response-reducer'
import giftPaymentBankInfoReducer from './auth/payment/gift-payment-bank-info-reducer'
import giftPaymentListReducer from './auth/payment/gift-payment-list-reducer'
import giftPaymentApiResponseReducer from './auth/payment/gift-payment-api-response-reducer'
import barCodeReducer from './auth/bar-code/bar-code-reducer'
import singlePaymentReducer from './auth/payment/single-payment-reducer'
import singlePaymentBankInfoReducer from './auth/payment/single-payment-bank-info-reducer'

import singlePurchaseBankInfoReducer from './auth/payment/single-purchase-bank-info-reducer'
import singlePurchaseListReducer from './auth/payment/single-purchase-list-reducer'
import singlePurchaseApiResponseReducer from './auth/payment/single-purchase-api-response-reducer'

import accountHistoryTableReducer from './auth/bill-page/account-history-table-reducer'
import compareBillReducer from './auth/bill-page/compare-bill-reducer'
import viewBillReducer from './auth/bill-page/view-bill-reducer'
import eBillReducer from './auth/document/ebill-reducer'
import purchaseHistoryTableReducer from './auth/bill-page/purchase-history-table-reducer'
import budgetBillingReducer from './auth/budget_billing/budget-billing-reducer'
import turnOnReducer from './guest/forms/turn-on-reducer'
import solarChoiceReducer from './auth/forms/solar-choice-reducer'
import cleanPowerReducer from './auth/forms/clean-power-reducer'
import turnOffReducer from './guest/forms/turn-off-reducer'
import transferServiceReducer from './auth/transfer_service/transfer-service-reducer'
import shareReducer from './auth/forms/share-reducer'
import healthyForestReducer from './auth/forms/healthy-forest-reducer'
import solarNonprofitReducer from './auth/forms/solar-nonprofit-reducer'
import eraProgramReducer from './auth/forms/era-program-reducer'
import economyPricePlanReducer from './auth/forms/economy-price-plan-reducer'
import evCommunityReducer from './auth/forms/ev-community-reducer'
import safetyNetReducer from './auth/forms/safety-net-reducer'
import authorizedPartyReducer from './auth/forms/authorized-party-reducer'

import { publicConfigApi } from '../slices/public-config-slice'

import { loginConstants } from '../constants/login-constants'
import { createRouterReducer } from '@lagunovsky/redux-react-router'

const allReducers = (history) => combineReducers({
    // New reducer slices
    [publicConfigApi.reducerPath]: publicConfigApi.reducer,

    // Old-style reducers
    accountInfo: accountInfoReducer,
    landlord: landlordReducer,
    login: loginReducer,
    pageHeader: pageHeaderReducer,
    myAccount: myAccountReducer,
    dashboard: dashboardReducer,
    bankAccounts: bankAccountsReducer,
    multiPayment: multiPaymentReducer,
    multiPaymentNgp: multiPaymentNgpReducer,
    dailyUsage: dailyUsageReducer,
    hourlyUsage: hourlyUsageReducer,
    rateMetaData: rateMetaDataReducer,
    billProjection: billProjectionReducer,
    accountHistory: accountHistoryReducer,
    prepayHistory: prepayHistoryReducer,
    accountHistoryTable: accountHistoryTableReducer,
    compareBill: compareBillReducer,
    viewBill: viewBillReducer,
    eBill: eBillReducer,
    purchaseHistoryTable: purchaseHistoryTableReducer,
    turnOn: turnOnReducer,
    weatherData: dailyWeatherDataReducer,
    budgetBilling: budgetBillingReducer,
    solarChoice: solarChoiceReducer,
    solarNonprofit: solarNonprofitReducer,
    economyPricePlan: economyPricePlanReducer,
    evCommunity:evCommunityReducer,
    cleanPower:cleanPowerReducer,
    transferService: transferServiceReducer,
    turnOff: turnOffReducer,
    share: shareReducer,
    healthyForest: healthyForestReducer,
    eraProgram: eraProgramReducer,
    safetyNet: safetyNetReducer,
    authorizedParty: authorizedPartyReducer,

    barCode: barCodeReducer,
    giftPaymentBankInfo: giftPaymentBankInfoReducer,
    giftPaymentList: giftPaymentListReducer,
    giftPaymentApiResponse: giftPaymentApiResponseReducer,

    singlePurchaseBankInfo: singlePurchaseBankInfoReducer,
    singlePurchaseList: singlePurchaseListReducer,
    singlePurchaseApiResponse: singlePurchaseApiResponseReducer,

    singlePaymentInfo: singlePaymentReducer,
    singlePaymentBankInfo: singlePaymentBankInfoReducer,

    // Guest reducers
    navigation: navigationReducer,
    guestPaymentBankInfo: guestPaymentBankInfoReducer,
    guestPaymentList: guestPaymentListReducer,
    guestPaymentApiResponse: guestPaymentApiResponseReducer,
    guestCreditCardLink: guestCreditCardLinkReducer,
    paymentLocation: paymentLocationReducer,

    navigator: createRouterReducer(history)
})

const rootReducer = (history) => {
    let combinedStore = allReducers(history)

    return (state, action) => {
        let nextState = (action.type === loginConstants.LOGOUT_SUCCESS || action.type === loginConstants.LOGIN_REQUEST)
                      ? {
                          // IMPORTANT: Any reducers not included in this list will be reset to initialState for the actions above.
                          // This is because they will be undefined when their reducers are called,
                          // which Redux then initializes that reducer back to its initial state.
                          [publicConfigApi.reducerPath]: state[publicConfigApi.reducerPath],

                          guestCreditCardLink: state.guestCreditCardLink,
                          guestPaymentApiResponse: state.guestPaymentApiResponse,
                          guestPaymentBankInfo: state.guestPaymentBankInfo,
                          guestPaymentList: state.guestPaymentList,
                          login: state.login, // login reducer manages its own state resets for login/logout
                          myAccount: state.myAccount, // myAccount reducer also manages its own state resets for login/logout
                          navigation: state.navigation,
                          paymentLocation: state.paymentLocation,
                          navigator: state.navigator // need to keep browser history state
                      } : state

        return combinedStore(nextState, action)
    }
}

export default rootReducer