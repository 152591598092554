import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { formConstants } from '../../../constants/form-constants'
import Checkbox from '@mui/material/Checkbox'
import { PatternFormat } from 'react-number-format'
import PropTypes from 'prop-types'
import validate from './../../../srp_modules/validate'
import StartServiceStepper from './start-stepper'

class AboutYou extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFirstNameError: false,
      firstNameError: '',
      hasLastNameError: false,
      lastNameError: '',
      hasIDNumberError: false,
      idNumberError: '',
      hasEmailError: false,
      emailError: '',
      hasPhoneNumberError: false,
      phoneNumberError: '',
      hasConfirmPhoneError: false,
      confirmPhoneError: '',
      hasConfirmEmail: false,
      confirmEmailError: '',
      hasSpouseFirstNameError: false,
      selectedLangFromTopSelector: this.props.myAccountPreferredLanguage
    }
    this.updateState = this.updateState.bind(this)
    this.validateConfirmPhone = this.validateConfirmPhone.bind(this)
    this.validateConfirmEmail = this.validateConfirmEmail.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.shouldRunValidation !== prevProps.shouldRunValidation) {
      this.checkValidation()
    }
  }

  // TODO: Upscale this to redux so we don't have to do the validation more than once
  validateRequiredField(fieldValue, errorStateValue) {
    this.setState({ [errorStateValue]: fieldValue.length === 0 })
  }

  validateCustomerNames(value, setValue) {
    if (value.match("^[ A-Za-z'-]*$")) {
      setValue(value)
    }
    else {
      return
    }
  }

  selectIdType(idType) {
    if(this.props.identificationType !== idType){
      this.setState({
        hasIDNumberError: false,
        idNumberError: ""
      })
    }

    this.props.idTypeChange(idType)
  }

  validateIdentificationNum(idNum) {
    if (idNum.match("^[ A-Za-z0-9'-]*$")) {
      this.props.idNumberChange(idNum)
    }
    else {
      return
    }
  }

  validateSsnItin(idNum) {
    if (idNum.trim().length === 0) {
      this.setState({
        hasIDNumberError: true,
        idNumberError: "identification_number_required"
      })
    }
    else if (idNum.trim().length < 11) {
      this.setState({
        hasIDNumberError: true,
        idNumberError: "identification_number_invalid"
      })
    }
    else {
      this.setState({
        hasIDNumberError: false,
        idNumberError: ""
      })
    }
  }

  validatePhone(){
    if(this.props.phoneNumber.length === 0) {
      this.setState({
        hasPhoneNumberError: true,
        phoneNumberError: "phone_number_required"
      })
    }
    else if (this.props.phoneNumber.length < 10) {
      this.setState({
        hasPhoneNumberError: true,
        phoneNumberError: "phone_numbers_is_too_short"
      })
    }
    else {
      this.setState({
        hasPhoneNumberError: false,
        phoneNumberError: ""
      })
    }
  }

  validateEmailAddress(email) {
    if (email.length === 0) {
      this.setState({
        hasEmailError: true,
        emailError: "email_required"
      })
    }
    else if (!validate.emailAddress(email)) {
      this.setState({
        hasEmailError: true,
        emailError: "email_invalid"
      })
    }
    else {
      this.setState({
        hasEmailError: false,
        emailError: ''
      })
    }
  }

  validateConfirmPhone() {
    if(this.props.confirmPhone.length === 0){
      this.setState({ hasConfirmPhoneError: true })
      this.setState({ confirmPhoneError: "confirm_phone_required" })
    }
    else if (this.props.phoneNumber !== this.props.confirmPhone) {
      this.setState({ hasConfirmPhoneError: true })
      this.setState({ confirmPhoneError: "phone_numbers_match" })
    } else {
      this.setState({ hasConfirmPhoneError: false })
      this.setState({ confirmPhoneError: "" })
    }
  }

  validateConfirmEmail() {
    if(this.props.confirmEmail.length === 0){
      this.setState({ hasConfirmEmail: true })
      this.setState({ confirmEmailError: "confirm_email_required" })
    }
    else if(this.props.email !== this.props.confirmEmail){
      this.setState({ hasConfirmEmail: true })
      this.setState({ confirmEmailError: "email_does_not_match" })
    }
    else if(!validate.emailAddress(this.props.confirmEmail)){
      this.setState({ hasConfirmEmail: true })
      this.setState({ confirmEmailError: "email_invalid" })
    }
    else{
      this.setState({ hasConfirmEmail: false })
      this.setState({ confirmEmailError: "" })
    }
  }

  checkValidation() {
    this.validateRequiredField(this.props.firstName, "hasFirstNameError", "firstNameError", "First name")
    this.validateRequiredField(this.props.lastName, "hasLastNameError", "lastNameError", "Last name")

    if (this.props.identificationType === formConstants.SOCIAL || this.props.identificationType === formConstants.ITIN) {
      this.validateSsnItin(this.props.identificationNumber)
    }
    else {
      this.validateRequiredField(this.props.identificationNumber, "hasIDNumberError", "idNumberError", "Identification number & type")
    }

    this.validateEmailAddress(this.props.email)
    this.validatePhone()

    this.validateConfirmPhone()
    this.validateConfirmEmail()
    if (this.props.authorizeSpouse) {
      this.validateRequiredField(this.props.spouseFirstName, "hasSpouseFirstNameError")
    }
  }

  goToNextFromStep() {
    this.checkValidation()
    if (this.state.hasFirstNameError === true ||
      this.state.hasLastNameError === true ||
      this.state.hasIDNumberError === true ||
      this.state.hasEmailError === true ||
      this.state.hasPhoneNumberError === true ||
      this.state.hasConfirmPhoneError === true ||
      this.state.hasConfirmEmail === true ||
      this.state.hasSpouseFirstNameError === true) { return }
    
    //this is to make sure that the preference of language is the same as select language from top nav bar
    if(this.props.myAccountPreferredLanguage === 'es' && this.props.preferredLanguage === formConstants.ENGLISH){
      this.props.preferredLanguageChange(formConstants.SPANISH)
    }

    if(this.props.myAccountPreferredLanguage === 'en' && this.props.preferredLanguage === formConstants.SPANISH){
      this.props.preferredLanguageChange(formConstants.ENGLISH)
    }

    this.props.goToSafety()

  }

  updateState(name, value) {
    this.setState({ [name]: value })
  }

  render() {
    return (<div>
      <StartServiceStepper
        currentStep={this.props.currentStep}
      />
      <div>
        <div>
          {/* <InputLabel htmlFor="firstName">{this.props.t("first_name")}</InputLabel> */}
          <div>
            <TextField id="firstName"
              value={this.props.firstName}
              fullWidth={true}
              disabled={this.props.isLoggedIn}
              error={this.state.hasFirstNameError}
              inputProps={{ maxLength: 30 }}
              className="mb-3"
              helperText={this.state.hasFirstNameError ? this.props.t("first_name_required") : ""}
              onBlur={e => this.validateRequiredField(e.target.value, "hasFirstNameError")}
              onChange={(e) => this.validateCustomerNames(e.target.value, this.props.firstNameChange)}
              label={this.props.t("first_name")}
            />
          </div>
        </div>
        <div>
          {/* <InputLabel htmlFor="middleName">{this.props.t("middle_name")}</InputLabel> */}
          <div>
            <TextField id="middleName"
              value={this.props.middleName}
              fullWidth={true}
              disabled={this.props.isLoggedIn}
              inputProps={{ maxLength: 30 }}
              className="mb-3"
              onChange={(e) => this.validateCustomerNames(e.target.value, this.props.middleNameChange)}
              label={this.props.t("middle_name")}
              InputProps={{ startAdornment: this.props.isLoggedIn && this.props.middleName.length === 0 ? <div /> : null }}
            />
          </div>
        </div>
        <div>
          {/* <InputLabel htmlFor="lastName">{this.props.t("last_name")}</InputLabel> */}
          <div>
            <TextField id="lastName"
              value={this.props.lastName}
              inputProps={{ maxLength: 30 }}
              className="mb-3"
              disabled={this.props.isLoggedIn}
              error={this.state.hasLastNameError}
              helperText={this.state.hasLastNameError ? this.props.t("last_name_required") : ""}
              onBlur={e => this.validateRequiredField(e.target.value, "hasLastNameError")}
              fullWidth={true}
              onChange={(e) => this.validateCustomerNames(e.target.value, this.props.lastNameChange)}
              label={this.props.t("last_name")}
            />
          </div>
        </div>
        <div className="mb-3">
          
          {/* <InputLabel htmlFor="idNumber" id="select-label">{this.props.t("identification_type")}</InputLabel> */}
          <TextField
            select
            value={this.props.identificationType} 
            onChange={event => this.selectIdType(event.target.value)} 
            fullWidth 
            label={this.props.t("identification_type")}
            >
            <MenuItem value={formConstants.SOCIAL}>{this.props.t("social_security")}</MenuItem>
            <MenuItem value={formConstants.DRIVERS_LICENSE}>{this.props.t("drivers_license")}</MenuItem>
            <MenuItem value={formConstants.STATE_ID}>{this.props.t("state_id")}</MenuItem>
            <MenuItem value={formConstants.GOVERNMENT_ID}>{this.props.t("government_id")}</MenuItem>
            <MenuItem value={formConstants.MILITARY_ID}>{this.props.t("military_id")}</MenuItem>
            <MenuItem value={formConstants.RESIDENCY_ID}>{this.props.t("us_residency_id")}</MenuItem>
            <MenuItem value={formConstants.NATURALIZATION}>{this.props.t("certificate_of_naturalization")}</MenuItem>
            <MenuItem value={formConstants.PASSPORT}>{this.props.t("passport")}</MenuItem>
            <MenuItem value={formConstants.MEXICAN_VOTER}>{this.props.t("mexico_voter_id")}</MenuItem>
            <MenuItem value={formConstants.TEMP_WORK_AUTH}>{this.props.t("temporary_work_auth")}</MenuItem>
            <MenuItem value={formConstants.ITIN}>ITIN</MenuItem>
          </TextField>
        </div>

        {this.props.identificationType !== formConstants.SOCIAL && (this.props.selectedPlan !== formConstants.MPOWER) && (!this.props.isLoggedIn) &&
          <div className="mb-3">
            <div className="alert alert-warning" role="alert">
              {this.props.t("waive_deposit_text")}
            </div>
          </div>
        }

        {
          this.props.identificationType === formConstants.SOCIAL && (this.props.selectedPlan !== formConstants.MPOWER) && (!this.props.isLoggedIn) &&
          <div className="mb-3">
            <div className="alert alert-info" role="alert">
              {this.props.t("social_security_text")}
            </div>
          </div>
        }

        {this.props.identificationType !== formConstants.SOCIAL && this.props.identificationType !== formConstants.ITIN &&
          <div>
            {/* <InputLabel htmlFor="idNumber">{this.props.t("identification_number")}</InputLabel> */}
            <div className="">
              <TextField id="idNumber"
                fullWidth={true}
                inputProps={{ maxLength: 30 }}
                className="mb-3"
                autoComplete="off"
                value={this.props.identificationNumber}
                error={this.state.hasIDNumberError}
                helperText={this.state.hasIDNumberError ? this.props.t("identification_number_required") : ""}
                onBlur={e => this.validateRequiredField(e.target.value, "hasIDNumberError")}
                onChange={(e) => this.validateIdentificationNum(e.target.value)} 
                label={this.props.t("identification_number")}
              />
            </div>
          </div>
        }

        {this.props.identificationType === formConstants.SOCIAL &&
          <div>
            {/* <InputLabel htmlFor="idNumber">{this.props.t("identification_number")}</InputLabel> */}
            <div className="">
              <PatternFormat
                id="socialSecurityAndItin"
                value={this.props.identificationNumber}
                valueIsNumericString={false}
                format="###-##-####"
                customInput={TextField}
                autoComplete="off"
                style={{ width: '100%' }}
                className="mb-3"
                type="tel"
                error={this.state.hasIDNumberError}
                helperText={this.state.idNumberError==='' ? '' : this.props.t(this.state.idNumberError)}
                onBlur={() => this.validateSsnItin(this.props.identificationNumber)}
                onValueChange={(e) => this.props.idNumberChange(e.formattedValue)}
                label={this.props.t("identification_number")}
              />
            </div>
          </div>}

        {this.props.identificationType === formConstants.ITIN &&
          <div>
            {/* <InputLabel htmlFor="idNumber">{this.props.t("identification_number")}</InputLabel> */}
            <div className="">
              <PatternFormat
                value={this.props.identificationNumber}
                valueIsNumericString={false}
                format="###-##-####"
                customInput={TextField}
                autoComplete="off"
                style={{ width: '100%' }}
                className="mb-3"
                type="tel"
                error={this.state.hasIDNumberError}
                helperText={this.state.idNumberError==='' ? '' : this.props.t(this.state.idNumberError)}
                onBlur={() => this.validateSsnItin(this.props.identificationNumber)}
                onValueChange={(e) => this.props.idNumberChange(e.formattedValue)}
                label={this.props.t("identification_number")}
              />
            </div>
          </div>}

        {!this.props.isLoggedIn &&
          <div className="pt-2">
            <label className="mr-3 text-muted" style={{ verticalAlign: 'middle' }}>{this.props.t("preferred_language")}</label>
            <FormGroup row>
              <FormControlLabel
                control={<Radio
                  checked={this.state.selectedLangFromTopSelector === 'en'}
                  onChange={e => {
                    this.setState({selectedLangFromTopSelector : 'en'})
                    this.props.preferredLanguageChange(e.target.value)
                  }}
                  value={formConstants.ENGLISH}
                  aria-label={this.props.t("english")}
                  color="primary"
                />}
                label={this.props.t("english")}
              />
              <FormControlLabel
                control={<Radio
                  checked={this.state.selectedLangFromTopSelector === 'es'}
                  onChange={e => {
                    this.setState({selectedLangFromTopSelector : 'es'})
                    this.props.preferredLanguageChange(e.target.value)
                  }}
                  value={formConstants.SPANISH}
                  aria-label={this.props.t("spanish")}
                  color="primary"
                />}
                label={this.props.t("spanish")}
              />
            </FormGroup>
          </div>
        }
        <div>
          {/* <InputLabel htmlFor="emailAddress">{this.props.t("email_address")}</InputLabel> */}
          <div>
            <TextField id="emailAddress"
              fullWidth={true}
              type="email"
              value={this.props.email}
              error={this.state.hasEmailError}
              inputProps={{ maxLength: 100 }}
              className="mb-3"
              helperText={this.state.emailError==='' ? '' : this.props.t(this.state.emailError)}
              onBlur={e => this.validateEmailAddress(e.target.value)}
              onChange={e => this.props.emailChange(e.target.value)}
              label={this.props.t("email_address")}
            />
          </div>
        </div>
        <div>
          {/* <InputLabel htmlFor="confirmEmailAddress">{this.props.t("confirm_email")}</InputLabel> */}
          <div>
            <TextField id="confirmEmailAddress"
              fullWidth={true}
              type="email"
              inputProps={{ maxLength: 100 }}
              className="mb-3"
              value={this.props.confirmEmail}
              error={this.state.hasConfirmEmail}
              helperText={this.state.confirmEmailError==='' ? '' : this.props.t(this.state.confirmEmailError)}
              onBlur={() => this.validateConfirmEmail()}
              onChange={e => this.props.updateConfirmEmail(e.target.value)}
              label={this.props.t("confirm_email")}
            />
          </div>
        </div>
        <div>
          {/* <InputLabel htmlFor="phoneNumber">{this.props.t("phone_number")}</InputLabel> */}
          <div>
            <PatternFormat
              value={this.props.phoneNumber}
              valueIsNumericString={true}
              format="(###) ###-####"
              customInput={TextField}
              style={{ width: '100%' }}
              className="mb-3"
              type="tel"
              error={this.state.hasPhoneNumberError}
              helperText={this.state.phoneNumberError==='' ? '' : this.props.t(this.state.phoneNumberError)}
              onBlur={() => this.validatePhone()}
              onValueChange={e => this.props.phoneNumberChange(e.value)}
              label={this.props.t("phone_number")}
            />
          </div>
        </div>
        <div>
          {/* <InputLabel htmlFor="confirmPhoneNumber">{this.props.t("confirm_phone")}</InputLabel> */}
          <div>
            <PatternFormat
              value={this.props.confirmPhone}
              valueIsNumericString={true}
              format="(###) ###-####"
              customInput={TextField}
              style={{ width: '100%' }}
              className="mb-3"
              type="tel"
              error={this.state.hasConfirmPhoneError}
              helperText={this.state.confirmPhoneError==="" ? "" : this.props.t(this.state.confirmPhoneError)}
              onBlur={() => this.validateConfirmPhone()}
              onValueChange={e => this.props.updateConfirmPhone(e.value)}
              label={this.props.t("confirm_phone")}
            />
          </div>
        </div>
        <div className="pt-2">
          <div>
            <label className="font-weight-bold">{this.props.t("include_spouse")}</label>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.authorizeSpouse}
                color="primary"
                onChange={e => this.props.authorizeSpouseChange(e.target.checked)}
              />
            }
            label={this.props.t("authorize_my_spouse")}
          />
        </div>

        {this.props.authorizeSpouse &&
          <div className="pt-2">
            {/* <InputLabel htmlFor="spouseFirstName">{this.props.t("spouse_first")}</InputLabel> */}
            <div className="">
              <TextField id="spouseFirstName"
                fullWidth={true}
                value={this.props.spouseFirstName}
                error={this.state.hasSpouseFirstNameError}
                helperText={this.state.hasSpouseFirstNameError ? this.props.t("Spouse first name is required") : ""}
                inputProps={{ maxLength: 30 }}
                className="mb-3"
                onChange={e => this.validateCustomerNames(e.target.value, this.props.spouseFirstNameChange)}
                onBlur={e => this.validateRequiredField(e.target.value, 'hasSpouseFirstNameError')}
                label={this.props.t("spouse_first")}
              />
            </div>
          </div>
        }

        {this.props.authorizeSpouse &&
          <div className="pt-2">
            {/* <InputLabel htmlFor="spouseLastName">{this.props.t("spouse_last")}</InputLabel> */}
            <div className="">
              <TextField id="spouseLastName"
                value={this.props.spouseLastName}
                inputProps={{ maxLength: 30 }}
                className="mb-3"
                fullWidth={true}
                onChange={e => this.validateCustomerNames(e.target.value, this.props.spouseLastNameChange)}
                label={this.props.t("spouse_last")}
              />
            </div>
          </div>
        }
      </div>
      <div>
        <div className="d-flex justify-content-end pt-2">
          <button className="btn srp-btn btn-lightblue mr-3" onClick={this.props.goToServiceDetails}>
            {this.props.t("previous")}
          </button>
          <button className="btn srp-btn btn-blue" onClick={this.goToNextFromStep.bind(this)}>
            {this.props.t("next")}
          </button>
        </div>
      </div>
    </div>)
  }
}

AboutYou.propTypes =
  {
    phoneNumber: PropTypes.string
    , email: PropTypes.string
    , shouldRunValidation: PropTypes.bool
    , firstName: PropTypes.string
    , lastName: PropTypes.string
    , identificationNumber: PropTypes.string
    , authorizeSpouse: PropTypes.bool
    , spouseFirstName: PropTypes.string
    , spouseLastName: PropTypes.string
    , firstNameChange: PropTypes.func
    , middleName: PropTypes.string
    , middleNameChange: PropTypes.func
    , identificationType: PropTypes.string
    , idNumberChange: PropTypes.func
    , preferredLanguage: PropTypes.string
    , emailChange: PropTypes.func
    , phoneNumberChange: PropTypes.func
    , authorizeSpouseChange: PropTypes.func
    , spouseFirstNameChange: PropTypes.func
    , spouseLastNameChange: PropTypes.func
    , lastNameChange: PropTypes.func
    , idTypeChange: PropTypes.func
    , preferredLanguageChange: PropTypes.func
    , selectedPlan: PropTypes.string
    , isLoggedIn: PropTypes.bool
    , confirmPhone: PropTypes.string
    , confirmEmail: PropTypes.string
    , goToServiceDetails: PropTypes.func
    , goToSafety: PropTypes.func
    , updateConfirmEmail: PropTypes.func
    , updateConfirmPhone: PropTypes.func
    , confirmEmailAddress: PropTypes.string
    , currentStep: PropTypes.number
    , t: PropTypes.func
  }

export default withTranslation('startService')(AboutYou)
