/* eslint-disable react/no-did-mount-set-state */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import AccountDetailsContainer from './acct-details-container'
import SelectDonationFormik from './select-donation-formik'
import Review from './review'
import Confirmation from './confirmation'

import CallAnytime from '../../myaccount_header/call-anytime'

import { autoFormStartEvent, formStartEvent, formEvent, formCompleteEvent, formErrorEvent } from '../../../srp_modules/analytics-events'

import { getContacts } from '../../../actions/auth/contact/contact-actions'
import { getBillAccounts, getServiceAddress, getPhoneNumber } from '../../../actions/auth/bill_account/bill-account-actions'
import {
    setActiveStep,
    setAccountType,
    setAccountDetails,
    setDonationType,
    setDonationAmount,
    setOtherDonationAmount,
    submitShareForm
} from '../../../actions/auth/forms/share-actions'
import { getRateMetaData } from '../../../actions/auth/usage/rate-meta-data-actions'
import LoginPrefillFormBanner from '../../common/login-prefill-form-banner'
import { shareConstants } from '../../../constants/share-constants'
import * as loadingStatus from '../../../constants/loading-status-constants'

import format from '../../../srp_modules/format'
import utils from '../../../srp_modules/utils'
import { withRouter } from '../../../srp_modules/with-router'

let progressCirclestyle = {
    marginTop: "10%",
    marginBottom: "20%"
}

class ShareContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            formStartEventEmitted: false
        }

        this.accountDetailsOnChange = this.accountDetailsOnChange.bind(this)
        this.setFormStep = this.setFormStep.bind(this)
        this.emitFormSubmitEvent = this.emitFormSubmitEvent.bind(this)
    }

    componentDidMount() {
        if (!this.props.isLoggedIn)
            return

        if (this.props.selectedBillAccount === 0) {
            if (this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_INIT
                || this.props.billAccountListStatus === loadingStatus.LOADING_STATUS_FAILURE) {
                this.props.getBillAccounts()
            }
            return
        }

        let updatedAccountDetails = {
            ...this.props.accountDetails,
            accountType: this.props.selectedBillAccountDetails.isCommercial
                ? shareConstants.ACOUNT_TYPE.COMMERCIAL
                : shareConstants.ACOUNT_TYPE.RESIDENTIAL,
            billAccount: format.srpAccountNumber(this.props.selectedBillAccount),
        }
        this.props.setAccountDetailsOnChange(updatedAccountDetails)

        if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getRateMetaDataInfo(this.props.selectedBillAccount)
        }
        if (this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.contactsStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getContactInfo(this.props.selectedBillAccount)
        }
        if (this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getServiceAddressInfo(this.props.selectedBillAccount)
        }

        if (this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT
            || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            this.props.getBestPhone(this.props.selectedBillAccount)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.isLoggedIn)
            return

        if (nextProps.selectedBillAccount === 0) {
            if (nextProps.billAccountListStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                this.props.getBillAccounts()
            }
            return
        }

        if (this.props.selectedBillAccount === nextProps.selectedBillAccount)
            return

        let updatedAccountDetails = {
            ...this.props.accountDetails,
            accountType: nextProps.selectedBillAccountDetails.isCommercial
                ? shareConstants.ACOUNT_TYPE.COMMERCIAL
                : shareConstants.ACOUNT_TYPE.RESIDENTIAL,
            billAccount: format.srpAccountNumber(nextProps.selectedBillAccount),
        }
        this.props.setAccountDetailsOnChange(updatedAccountDetails)

        if (nextProps.rateMetaDataStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getRateMetaDataInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.contactsStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getContactInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.serviceAddressStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getServiceAddressInfo(nextProps.selectedBillAccount)
        }

        if (nextProps.phoneNumberStatus !== loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            this.props.getBestPhone(nextProps.selectedBillAccount)
        }
    }

    accountDetailsOnChange(newAccountDetails) {
        const accountDetails = this.props.accountDetails
        const activeStep = this.props.activeStep
        const formStartEventEmitted = this.state.formStartEventEmitted

        const accountDetailsChanged =
            newAccountDetails.accountType !== accountDetails.accountType ||
            newAccountDetails.billAccount !== accountDetails.billAccount ||
            newAccountDetails.firstName !== accountDetails.firstName ||
            newAccountDetails.middleInitial !== accountDetails.middleInitial ||
            newAccountDetails.lastName !== accountDetails.lastName ||
            newAccountDetails.businessName !== accountDetails.businessName ||
            newAccountDetails.dbaName !== accountDetails.dbaName ||
            newAccountDetails.contactName !== accountDetails.contactName ||
            newAccountDetails.contactPosition !== accountDetails.contactPosition ||
            newAccountDetails.serviceAddressTwo !== accountDetails.serviceAddressTwo ||
            newAccountDetails.serviceAddressOne !== accountDetails.serviceAddressOne ||
            newAccountDetails.city !== accountDetails.city ||
            newAccountDetails.zipCode !== accountDetails.zipCode ||
            newAccountDetails.emailAddress !== accountDetails.emailAddress ||
            newAccountDetails.phoneNumber !== accountDetails.phoneNumber

        if (accountDetailsChanged === true) {
            if (formStartEventEmitted === false) {
                const stepName = shareConstants.getStepName(activeStep)
                formStartEvent(shareConstants.ADOBE_ANALYTICS_FORM_NAME, stepName)

                this.setState({ formStartEventEmitted: true })
            }
        }

        this.props.setAccountDetailsOnChange(newAccountDetails)
    }

    setFormStep(step) {
        if (this.state.formStartEventEmitted === false) {
            // user going to next screen without touching the pre-filled form
            // need to auto generate formStartEvent
            const stepName = shareConstants.getStepName(this.props.activeStep)
            autoFormStartEvent(shareConstants.ADOBE_ANALYTICS_FORM_NAME, stepName)

            this.setState({ formStartEventEmitted: true })
        }

        this.props.setActiveStepOnClick(step)
        document.getElementById("topOfShareForm").scrollIntoView()

        formEvent(shareConstants.ADOBE_ANALYTICS_FORM_NAME, shareConstants.getStepName(step))
    }

    emitFormSubmitEvent() {
        const formName = shareConstants.ADOBE_ANALYTICS_FORM_NAME
        const submitShareFormStatus = this.props.submitShareFormStatus
        const accountDetails = this.props.accountDetails

        const donationType = this.props.donationType === shareConstants.DONATION_TYPE.MONTHLY
            ? "monthly pledge"
            : this.props.donationType === shareConstants.DONATION_TYPE.ONE_TIME
                ? "one-time pledge"
                : "cancellation"

        const donationAmount = this.props.donationType === shareConstants.DONATION_TYPE.DISCONTINUE
            ? "(n/a)"
            : this.props.donationAmount === shareConstants.DONATION_AMOUNT.OTHER
                ? `$${this.props.otherDonationAmount}`
                : `$${this.props.donationAmount}.00`

        if (submitShareFormStatus === loadingStatus.LOADING_STATUS_FAILURE) {
            formErrorEvent(formName)
        }

        if (submitShareFormStatus === loadingStatus.LOADING_STATUS_SUCCESS) {
            const meta = {
                loggedIn: this.props.isLoggedIn,
                accountType: accountDetails.accountType === shareConstants.ACOUNT_TYPE.RESIDENTIAL
                    ? 'residential' : 'commercial',
                donationType,
                donationAmount
            }
            formCompleteEvent(formName, meta)
        }
    }

    getFormDetails() {
        if (this.props.isLoggedIn) {
            if (this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_INIT || this.props.rateMetaDataStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.contactsStatus === loadingStatus.LOADING_STATUS_INIT || this.props.contactsStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_INIT || this.props.serviceAddressStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS
                || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_INIT || this.props.phoneNumberStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
                return <div className="d-flex justify-content-center"><CircularProgress size={80} thickness={5} style={progressCirclestyle} /></div>
            }
        }

        const t = this.props.t
        const i18n = this.props.i18n
        const isSpanish = (i18n.language === "es")
        const isCommercial = (this.props.accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL)
        const supportNumber = utils.GetCustomerServicePhoneNumber(isCommercial, isSpanish)
        const supportNumberFmt = utils.GetCustomerServicePhoneNumberFmt(isCommercial, isSpanish)

        return (<div className="row">
            <div className="col-lg-6 col-12">
                <div className="srp-card-header" id="topOfShareForm">{t(shareConstants.SHARE_STEPS[this.props.activeStep].title)}</div>
                <div className="srp-card-body">
                    <div className="srp-card-details pb-0">
                        {!this.props.isLoggedIn && this.props.activeStep === 0
                            ? <LoginPrefillFormBanner redirectToForm={'/share'} />
                            : null}
                        <div className="d-lg-block d-none">
                            <div className="col-9 p-0">
                                <Stepper activeStep={this.props.activeStep} className="p-0">
                                    {shareConstants.SHARE_STEPS.map(item => {
                                        return (
                                            <Step key={item.step}>
                                                <StepLabel>{t(item.label)}</StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </div>
                        </div>
                        <div className="d-lg-none d-block">
                            <div className="p-0">
                                <Stepper activeStep={this.props.activeStep} alternativeLabel className="p-0">
                                    {shareConstants.SHARE_STEPS.map(item => {
                                        return (
                                            <Step key={item.step}>
                                                <StepLabel>{t(item.label)}</StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </div>
                        </div>

                    </div>
                    {this.props.activeStep === 0
                        ? <AccountDetailsContainer
                            activeStep={this.props.activeStep}
                            setActiveStepOnClick={this.setFormStep}
                            setAccountTypeOnClick={this.props.setAccountTypeOnClick}
                            accountDetails={this.props.accountDetails}
                            t={t} i18n={i18n}
                            setAccountDetailsOnChange={(newAccountDetails) => this.accountDetailsOnChange(newAccountDetails)} />
                        : this.props.activeStep === 1
                            ? <SelectDonationFormik
                                activeStep={this.props.activeStep}
                                setActiveStepOnClick={this.setFormStep}
                                donationType={this.props.donationType}
                                setDonationTypeOnClick={this.props.setDonationTypeOnClick}
                                donationAmount={this.props.donationAmount}
                                setDonationAmountOnClick={this.props.setDonationAmountOnClick}
                                otherDonationAmount={this.props.otherDonationAmount}
                                t={t} i18n={i18n}
                                setOtherDonationAmountOnChange={this.props.setOtherDonationAmountOnChange} />
                            : <Review
                                activeStep={this.props.activeStep}
                                setActiveStepOnClick={this.setFormStep}
                                accountDetails={this.props.accountDetails}
                                donationType={this.props.donationType}
                                donationAmount={this.props.donationAmount}
                                otherDonationAmount={this.props.otherDonationAmount}
                                submitShareFormOnClick={this.props.submitShareFormOnClick}
                                t={t} i18n={i18n}
                                submitShareFormStatus={this.props.submitShareFormStatus} />
                    }
                </div>
            </div>

            <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                <div className="srp-card-header">{t("Need help?")}</div>
                <div className="srp-card-body srp-orange-background">
                    {this.props.accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL
                        ? <div className="srp-card-details">
                            <p className="d-lg-block d-none mb-0">
                                {t("contact_info_commercial_desktop", {phone: supportNumberFmt})}
                            </p>
                            <p className="d-lg-none d-block mb-0">
                                <Trans i18nKey="contact_info_commercial_mobile" t={t}>
                                    Should you have questions, please contact the SRP Business Contact Center at
                                    <a href={"tel:"+supportNumber}>{{phone: supportNumberFmt}}</a>,
                                    Monday through Friday, 7 a.m.-5 p.m.
                                </Trans>
                            </p>
                        </div>
                        : <div className="srp-card-details">
                            <p className="d-lg-block d-none mb-0">
                                {t("contact_info_residential_desktop", {phone: supportNumberFmt})}
                            </p>
                            <p className="d-lg-none d-block mb-0">
                                <Trans i18nKey="contact_info_residential_mobile" t={t}>
                                    For questions please contact SRP Residential Customer Services at
                                    <a href={"tel:"+supportNumber}>{{phone: supportNumberFmt}}</a>,
                                    available to serve you 24 hours a day, seven days a week.
                                </Trans>
                            </p>
                        </div>
                    }

                </div>
            </div>
        </div>)
    }

    render() {
        const t = this.props.t
        const i18n = this.props.i18n

        if (this.props.submitShareFormStatus === loadingStatus.LOADING_STATUS_INIT || this.props.submitShareFormStatus === loadingStatus.LOADING_STATUS_IN_PROGRESS) {
            return (
                <div>
                    <div className="d-block d-md-none">
                        <Button
                            variant="contained"
                            fullWidth
                            className="justify-content-start nav-button-responsive"
                            onClick={() => this.props.router.navigate(-1)}
                            disableRipple>
                            <Icon className="material-icons mr-2" style={{ color: 'rgb(33,108,224' }}>keyboard_backspace</Icon>
                            {t("Previous page")}
                        </Button>
                    </div>
                    <div className="container py-3">
                        <CallAnytime />
                        <h3 className="d-lg-block d-none mt-3 mb-4 pb-4">{t("SHARE a little and help a lot")}</h3>
                        <h4 className="d-lg-none d-block mt-3 mb-4">{t("SHARE a little and help a lot")}</h4>

                        <div className="d-none d-md-block mb-4">
                            <h5 className="d-flex justify-content-start align-items-center">
                                <button className="displayAsLink" onClick={() => this.props.router.navigate(-1)}>{t("Previous page")}</button>
                                <i className="material-icons" style={{ color: 'rgba(72, 72, 74, 0.376)' }} >keyboard_arrow_right</i>
                                <span style={{ color: '#707070' }}>{t("SHARE")}</span>
                            </h5>
                        </div>

                        {this.getFormDetails()}

                    </div>
                </div>
            )
        }
        else {
            this.emitFormSubmitEvent()

            return (<Confirmation
                isLoggedIn={this.props.isLoggedIn}
                isCommercial={this.props.accountDetails.accountType === shareConstants.ACOUNT_TYPE.COMMERCIAL}
                emailAddress={this.props.accountDetails.emailAddress}
                t={t} i18n={i18n}
                submitShareFormStatus={this.props.submitShareFormStatus} />)
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state.login,
        ...state.accountInfo.billAccount,
        ...state.share,
        ...state.accountInfo.contact,
        rateMetaDataStatus: state.rateMetaData.rateMetaDataStatus,
        isBusinessOnResidentialPlan: state.rateMetaData.rateMetaData.isBusinessOnResidentialPlan }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBillAccounts: () => {
            dispatch(getBillAccounts())
        },
        getContactInfo: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getRateMetaDataInfo: (billAccount) => {
            dispatch(getRateMetaData(billAccount))
        },
        getServiceAddressInfo: (billAccount) => {
            dispatch(getServiceAddress(billAccount))
        },
        getBestPhone: (billAccount) => {
            dispatch(getPhoneNumber(billAccount))
        },
        setActiveStepOnClick: (activeStep) => {
            dispatch(setActiveStep(activeStep))
        },
        setAccountTypeOnClick: (accountType) => {
            dispatch(setAccountType(accountType))
        },
        setAccountDetailsOnChange: (accountDetails) => {
            dispatch(setAccountDetails(accountDetails))
        },
        setDonationTypeOnClick: (donationType) => {
            dispatch(setDonationType(donationType))
        },
        setDonationAmountOnClick: (donationAmount) => {
            dispatch(setDonationAmount(donationAmount))
        },
        setOtherDonationAmountOnChange: (donationAmount) => {
            dispatch(setOtherDonationAmount(donationAmount))
        },
        submitShareFormOnClick: (accountDetails, donationType, donationAmount, otherDonationAmount) => {
            dispatch(submitShareForm(accountDetails, donationType, donationAmount, otherDonationAmount))
        }
    }
}

ShareContainer.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func
    }),
    isLoggedIn: PropTypes.bool,
    loginEmail: PropTypes.string,
    selectedBillAccount: PropTypes.number,
    selectedBillAccountDetails: PropTypes.object,

    billAccountListStatus: PropTypes.string.isRequired,
    getBillAccounts: PropTypes.func,

    rateMetaDataStatus: PropTypes.string,
    getRateMetaDataInfo: PropTypes.func,
    isBusinessOnResidentialPlan: PropTypes.bool,

    contactsStatus: PropTypes.string,
    getContactInfo: PropTypes.func,

    serviceAddressStatus: PropTypes.string,
    getServiceAddressInfo: PropTypes.func,

    phoneNumberStatus: PropTypes.string,
    getBestPhone: PropTypes.func,

    activeStep: PropTypes.number,
    setActiveStepOnClick: PropTypes.func,

    setAccountTypeOnClick: PropTypes.func,
    accountDetails: PropTypes.object,
    setAccountDetailsOnChange: PropTypes.func,

    donationType: PropTypes.number,
    setDonationTypeOnClick: PropTypes.func,
    donationAmount: PropTypes.number,
    setDonationAmountOnClick: PropTypes.func,
    otherDonationAmount: PropTypes.string,
    setOtherDonationAmountOnChange: PropTypes.func,

    submitShareFormStatus: PropTypes.string,
    submitShareFormOnClick: PropTypes.func,

    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default withTranslation("shareProgram")(withRouter(connect(mapStateToProps, mapDispatchToProps)(ShareContainer)))