import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import { formConstants } from '../../../constants/form-constants'
import TextField from '@mui/material/TextField'
import i18n from '../../../i18n'

class BillDate extends Component {
  constructor(props){
    super(props)
    this.state = {
      hasDueDateError: false
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.shouldRunValidation !== prevProps.shouldRunValidation){
      this.dueDateRequiredField(this.props.dayOfMonth)
    }
  }

  dueDateRequiredField(fieldValue){
    if(this.props.selectedPlan === formConstants.MPOWER){
      this.setState({hasDueDateError: false})
      return
    }

    if(fieldValue.length === 0 || fieldValue === 0){
      this.setState({hasDueDateError: true})
    } else {
      this.setState({hasDueDateError: false})
    }
  }

  selectCustomDueDate(daySelected){
    this.props.setCustomDueDate(daySelected)
    this.setState({hasDueDateError: daySelected.length === 0 || daySelected === 0})
  }

  onBlurCustomDueDate(daySelected){
    this.props.setCustomDueDate(daySelected)
    this.setState({hasDueDateError: daySelected.length === 0 || daySelected === 0})
  }

  render(){
    return (<div>
      <div className="card mb-4 border-0" style={{background: "#E0E7F0"}}>
          <div className="card-body">
            <h5 className="card-title">{this.props.t("paperless_billing")}</h5>
            <FormControlLabel
              control={
                <Checkbox
                    checked={this.props.isPaperlessBilling}
                    onChange={event=> this.props.paperlessBillingChange(event.target.checked)}
                    color="primary"
                />
              }
              label={this.props.t("go_paperless")} />
          </div>
        </div>
        <div className="mb-4">
          <div className="px-3" >
          <FormControl fullWidth error={this.state.hasDueDateError}>
            <FormControlLabel sx={{width: '55%', minWidth: '340px'}}
              control={
                <TextField select value={this.props.dayOfMonth} onChange={(e) => this.selectCustomDueDate(e.target.value)}
                  onBlur={(e) => this.onBlurCustomDueDate(e.target.value)}
                  label={this.props.t("custom_due_date")}
                  sx={{minWidth: '69%'}}
                  >
                  <MenuItem value={0}/>
                  <MenuItem value={1}>{i18n.language === "es" ? 1 : "1st"}</MenuItem>
                  <MenuItem value={2}>{i18n.language === "es" ? 2 : "2nd"}</MenuItem>
                  <MenuItem value={3}>{i18n.language === "es" ? 3 : "3rd"}</MenuItem>
                  <MenuItem value={4}>{i18n.language === "es" ? 4 : "4th"}</MenuItem>
                  <MenuItem value={5}>{i18n.language === "es" ? 5 : "5th"}</MenuItem>
                  <MenuItem value={6}>{i18n.language === "es" ? 6 : "6th"}</MenuItem>
                  <MenuItem value={7}>{i18n.language === "es" ? 7 : "7th"}</MenuItem>
                  <MenuItem value={8}>{i18n.language === "es" ? 8 : "8th"}</MenuItem>
                  <MenuItem value={9}>{i18n.language === "es" ? 9 : "9th"}</MenuItem>
                  <MenuItem value={10}>{i18n.language === "es" ? 10 : "10th"}</MenuItem>
                  <MenuItem value={11}>{i18n.language === "es" ? 11 : "11th"}</MenuItem>
                  <MenuItem value={12}>{i18n.language === "es" ? 12 : "12th"}</MenuItem>
                  <MenuItem value={13}>{i18n.language === "es" ? 13 : "13th"}</MenuItem>
                  <MenuItem value={14}>{i18n.language === "es" ? 14 : "14th"}</MenuItem>
                  <MenuItem value={15}>{i18n.language === "es" ? 15 : "15th"}</MenuItem>
                  <MenuItem value={16}>{i18n.language === "es" ? 16 : "16th"}</MenuItem>
                  <MenuItem value={17}>{i18n.language === "es" ? 17 : "17th"}</MenuItem>
                  <MenuItem value={18}>{i18n.language === "es" ? 18 : "18th"}</MenuItem>
                  <MenuItem value={19}>{i18n.language === "es" ? 19 : "19th"}</MenuItem>
                  <MenuItem value={20}>{i18n.language === "es" ? 20 : "20th"}</MenuItem>
                  <MenuItem value={21}>{i18n.language === "es" ? 21 : "21st"}</MenuItem>
                  <MenuItem value={22}>{i18n.language === "es" ? 22 : "22nd"}</MenuItem>
                  <MenuItem value={23}>{i18n.language === "es" ? 23 : "23rd"}</MenuItem>
                  <MenuItem value={24}>{i18n.language === "es" ? 24 : "24th"}</MenuItem>
                  <MenuItem value={25}>{i18n.language === "es" ? 25 : "25th"}</MenuItem>
                  <MenuItem value={26}>{i18n.language === "es" ? 26 : "26th"}</MenuItem>
                  <MenuItem value={27}>{i18n.language === "es" ? 27 : "27th"}</MenuItem>
                  <MenuItem value={28}>{i18n.language === "es" ? 28 : "28th"}</MenuItem>
                </TextField>
              }
                  label={<div className="ml-3">{this.props.t("of_the_month")}</div>}
            />
              { this.state.hasDueDateError &&
                <FormHelperText>{this.props.t("due_date_required")}</FormHelperText>}
            </FormControl>
          </div>
        </div>
    </div>)
  }
}

BillDate.propTypes = {
  shouldRunValidation: PropTypes.bool.isRequired,
  dayOfMonth: PropTypes.number.isRequired,
  setCustomDueDate: PropTypes.func.isRequired,
  isPaperlessBilling: PropTypes.bool.isRequired,
  paperlessBillingChange: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  t: PropTypes.func
}

export default withTranslation('startService')(BillDate)