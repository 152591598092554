import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import i18n from '../i18n'
import config from 'my-account-config'

const initialState = {
    startDate: "",
    endDate: "",
    title: "",
    message: ""
}

export const publicConfigApi = createApi({
    reducerPath: 'publicConfig',
    initialState,
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiBaseUrl
    }),
    endpoints: build => ({
        getConfigInfo: build.query({
            query: () => 'public_config.json',
            transformResponse: (response) => {
                if (!response.translations)
                    return response

                // NOTE: Side effect; is this okay to do here?
                let translations = response.translations
                Object.keys(translations).forEach(key => {
                    i18n.addResourceBundle(key, 'getConfigInfo', translations[key])
                })

                return response
            }
        })
    })
})

export const { useGetConfigInfoQuery } = publicConfigApi