import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import config from 'my-account-config'
import Divider from '@mui/material/Divider'

import DeleteSafetyNetDialog from '../../forms/safety_net/delete-safety-net-dialog'

import eppStatusModule from '../../../srp_modules/epp-status-module'
import evCommunityStatusModule from '../../../srp_modules/ev-community-status-module'
import MyProgramDetails from './my-program-details'
import MyProgramDetailsResponsive from './my-program-details-responsive'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

import { programConstants } from '../../../constants/program-constants'
import i18n from '../../../i18n'

const MyPrograms = ({ programs, hasCommunitySolar, hasEnergyScorecard, numOfEnrolledPrograms, autopayInfo, budgetBillingAmount,
    isBudgetBillingActive, isBudgetBillingEligible, customDueDay, isCustomDueDateEligible, isHomeEnergyProfileAvailable,
    hashedAccountForOpower, singleBillAccount,
    solarChoicePercentage, sharePledge, customerNames, t, i18n,
    selectedBillAccountDetails, handleRemoveSafetyNetDialog, showRemoveSafetyNetDialog }) => {

    let isBudgetBillingEnrolled = programs.budgetBilling.isEnrolled && isBudgetBillingActive
    let isBudgetBillingAvailable = (programs.budgetBilling.isAvailable || (programs.budgetBilling.isEnrolled && !isBudgetBillingActive))
        && isBudgetBillingEligible

    let eppStatusDisplayInfo = eppStatusModule.displayEppStatusOnProgramPage(programs.economicPricePlan.isPendingReview, programs.economicPricePlan.statusId, programs.economicPricePlan.discountValue, t)
    let eppDisplayEppOnProgramPage = eppStatusModule.displayEppOnProgramPage(programs.economicPricePlan.statusId)
    let eppApplyEligibility = eppStatusModule.getEppApplyEligibility(selectedBillAccountDetails, programs.economicPricePlan, customerNames)

    let evCommmunityApplyEligibility = evCommunityStatusModule.getEVCommunitySignupEligibility(selectedBillAccountDetails, programs.evCommunityCredits, customerNames)

    const isSpanish = i18n.language==="es"

    let getDisplayPrograms = (t, hashedAccountForOpower) => {
        let enrolledPrograms = []
        let availablePrograms = []

        if (programs.autopay.isEnrolled) {
            enrolledPrograms.push({
                program: programConstants.AUTOPAY,
                programText: t('SurePay'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`,
                descriptionText: autopayInfo.surePayDate !== "0001-01-01T00:00:00" && autopayInfo.surePayDate !== null ? t("surepay_date", {surePayDate: formatDayWithOrdinal(DateTime.fromISO(autopayInfo.surePayDate).day)}) : "",
                actionText: t('Update bank'),
                actionText2: '',
                actionLink: '/myaccount/payment/autopay/edit',
                actionLink2: ''
            })
        } else if (programs.autopay.isAvailable && autopayInfo.isEligibleToEnroll) {
            availablePrograms.push({
                program: programConstants.AUTOPAY,
                programText: t('SurePay'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`,
                descriptionText: t('Automatic bill payments'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/myaccount/payment/autopay',
                actionLink2: ''
            })
        }

        if (isBudgetBillingEnrolled) {
            enrolledPrograms.push({
                program: programConstants.BUDGET_BILLING,
                programText: t('Budget Billing'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/budget-billing-residencial`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`,
                descriptionText: t("Amount_dollars", {amount: budgetBillingAmount.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}),
                actionText: '',
                actionText2: '',
                actionLink: '',
                actionLink2: ''
            })
        } else if (isBudgetBillingAvailable) {
            availablePrograms.push({
                program: programConstants.BUDGET_BILLING,
                programText: t('Budget Billing'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/budget-billing-residencial`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/budget-billing-residential`,
                descriptionText: t('Predictable monthly payments'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/budgetbilling',
                actionLink2: ''
            })
        }

        if (programs.solarChoice.isEnrolled) {
            enrolledPrograms.push({
                program: programConstants.SOLAR_CHOICE,
                programText: t('SRP Solar Choice'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/programa-solar-choice`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`,
                descriptionText: t("Renewables_percent", {solarChoicePercentage: solarChoicePercentage * 100}),
                actionText: t('Change'),
                actionText2: '',
                actionLink: '/forms/solarchoice/change',
                actionLink2: ''
            })
        } else if (programs.solarChoice.isAvailable) {
            availablePrograms.push({
                program: programConstants.SOLAR_CHOICE,
                programText: t('SRP Solar Choice'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/programa-solar-choice`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/solar-choice-program`,
                descriptionText: t('Sign-up for renewable energy'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/forms/solarchoice/signup',
                actionLink2: ''
            })
        }

        if (isHomeEnergyProfileAvailable) {
            let homeEnergyProfileLink = (hashedAccountForOpower || singleBillAccount) ? config.homeEnergyProfile : ''
            let energyInsightsLink = (hashedAccountForOpower || singleBillAccount) ? config.energyInsights : ''

            if (hashedAccountForOpower) {
                const queryParam = `ou-entity-id=${hashedAccountForOpower}`
                const homeEnergyProfileLinkHasQueryParam = (homeEnergyProfileLink.indexOf('?') > -1)
                const energyInsightsLinkHasQueryParam = (energyInsightsLink.indexOf('?') > -1)

                homeEnergyProfileLink += (homeEnergyProfileLinkHasQueryParam ? '&' : '?') + queryParam
                energyInsightsLink += (energyInsightsLinkHasQueryParam ? '&' : '?') + queryParam
            }

            availablePrograms.push({
                program: programConstants.HOME_ENERGY_PROFILE,
                programText: t('Home Energy Profile'),
                programLink: isSpanish ?
                    `${config.srpnetBaseUrlEs}reembolsos-ahorros-de-energia/hogar/auditoria-ahorro-energia-hogar` :
                    `${config.srpnetBaseUrl}energy-savings-rebates/home/home-energy-savings-audit`,
                descriptionText: t('Home_Energy_Profile_description'),
                actionText: t('Complete energy profile'),
                actionText2: '',
                actionLink: homeEnergyProfileLink,
                actionLink2: ''
            })

            availablePrograms.push({
                program: programConstants.ENERGY_INSIGHTS,
                programText: t('Energy Insights'),
                programLink: energyInsightsLink,
                descriptionText: t('Find out where your home may be wasting energy.'),
                actionText: t('Explore my usage'),
                actionText2: '',
                actionLink: energyInsightsLink,
                actionLink2: ''
            })
        }

        if (hasCommunitySolar) {
            enrolledPrograms.push({
                program: programConstants.COMMUNITY_SOLAR,
                programText: t('Community Solar'),
                descriptionText: t('Solar energy credit'),
                actionText: t('Go to Document Center'),
                actionText2: '',
                actionLink: '/myaccount/profile/documents',
                actionLink2: ''
            })
        }

        if (programs.customDueDate.isEnrolled && isCustomDueDateEligible) {
            enrolledPrograms.push({
                program: programConstants.CUSTOM_DUE_DATE,
                programText: t('Custom Due Date'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/cambiar-fecha-pago`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/change-bill-due-date`,
                descriptionText: !programs.autopay.isEnrolled ? t("custom_due_date", {customDueDay: i18n.language === 'en' ? formatDayWithOrdinal(customDueDay) : customDueDay}) : "",
                actionText: t('Change'),
                actionText2: '',
                actionLink: '/customduedate',
                actionLink2: ''
            })
        } else if (programs.customDueDate.isAvailable && isCustomDueDateEligible) {
            availablePrograms.push({
                program: programConstants.CUSTOM_DUE_DATE,
                programText: t('Custom Due Date'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/cambiar-fecha-pago`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/change-bill-due-date`,
                descriptionText: t('Pick your bill due date'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/customduedate',
                actionLink2: ''
            })
        }

        if (hasEnergyScorecard) {
            enrolledPrograms.push({
                program: programConstants.ENERGY_SCORECARD,
                programText: t('Energy Scorecard'),
                programLink: '',
                descriptionText: t('Your energy usage comparison'),
                actionText: t('Go to Document Center'),
                actionText2: '',
                actionLink: '/myaccount/profile/documents',
                actionLink2: ''
            })
        }

        if (programs.share.isEnrolled) {
            enrolledPrograms.push({
                program: programConstants.SHARE,
                programText: t('SHARE'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/ayudando-vecinos`
                    : `${config.srpnetBaseUrl}account/donate/help-neighbors`,
                descriptionText: t("SHARE_pledge_amount", {pledgeAmount: sharePledge}),
                actionText: t('Change'),
                actionText2: '',
                actionLink: '/share',
                actionLink2: ''
            })
        } else if (programs.share.isAvailable) {
            availablePrograms.push({
                program: programConstants.SHARE,
                programText: t('SHARE'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/ayudando-vecinos`
                    : `${config.srpnetBaseUrl}account/donate/help-neighbors`,
                descriptionText: t('Help those in need through SHARE'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/share',
                actionLink2: ''
            })
        }

        if (programs.economicPricePlan.isEnrolled && eppDisplayEppOnProgramPage) {
            enrolledPrograms.push({
                program: programConstants.ECONOMIC_PRICE_PLAN,
                programText: t('Economy Price Plan'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/electricidad-residencial/plan-precio-economico`
                    : `${config.srpnetBaseUrl}customer-service/residential-electric/economy-price-plan`,
                descriptionText: eppStatusDisplayInfo.statusText,
                docusign: {
                    showReapplyLink: eppStatusDisplayInfo.showReapplyLink,
                }
            })
        }

        if (eppApplyEligibility) {
            availablePrograms.push({
                program: programConstants.ECONOMIC_PRICE_PLAN,
                programText: t('Economy Price Plan'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}servicio-al-cliente/electricidad-residencial/plan-precio-economico`
                    : `${config.srpnetBaseUrl}customer-service/residential-electric/economy-price-plan`,
                descriptionText: t("epp_save_amount", {savings: (programs.economicPricePlan.discountValue * 12).toFixed(0)}),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/forms/economypriceplan/signup',
                actionLink2: ''
            })
        }

        if (evCommmunityApplyEligibility && programs.evCommunityCredits.optionValue === 2) {
            availablePrograms.push({
                program: programConstants.EV_COMMUNITY_CREDITS,
                programText: t('SRP EV Community'),
                programLink: `${config.srpnetBaseUrl}energy-savings-rebates/home/join-ev-community`, // no Spanish version, as of 8/1/2024
                descriptionText: t("SRP_EV_Community_description"),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/forms/evcommunity/signup',
                actionLink2: ''
            })
        }

        if (evCommmunityApplyEligibility && programs.evCommunityCredits.optionValue < 2) {
            let creditsAvailable = programs.evCommunityCredits.optionValue

            let programText = t("50_dollars_credit_received")
            if (creditsAvailable === 0) {
                programText = t("100_dollars_credit_received")
            }

            enrolledPrograms.push({
                program: programConstants.EV_COMMUNITY_CREDITS,
                programText: t('SRP EV Community'),
                programLink: `${config.srpnetBaseUrl}energy-savings-rebates/home/join-ev-community`, // no Spanish version, as of 8/1/2024
                descriptionText: programText,
                actionText: creditsAvailable === 1 ? t('Add vehicle') : '',
                actionText2: '',
                actionLink: creditsAvailable === 1 ? '/forms/evcommunity/signup' : '',
                actionLink2: ''
            })
        }

        if (programs.healthyForest.isEnrolled) {
            enrolledPrograms.push({
                program: programConstants.HEALTHY_FOREST,
                programText: t('SRP Healthy Forest Initiative'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/iniciativa-bosque-sanos`
                    : `${config.srpnetBaseUrl}account/donate/healthy-forest-initiative`,
                descriptionText: t("Amount_dollars", {amount: (programs.healthyForest.optionValue * 3).toFixed(0)}),
                actionText: t('Change'),
                actionText2: '',
                actionLink: '/forms/healthyforest/change',
                actionLink2: ''

            })
        } else if (programs.healthyForest.isAvailable) {
            availablePrograms.push({
                program: programConstants.HEALTHY_FOREST,
                programText: t('SRP Healthy Forest Initiative'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/iniciativa-bosque-sanos`
                    : `${config.srpnetBaseUrl}account/donate/healthy-forest-initiative`,
                descriptionText: t('Help restore our forests & protect our water supply'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/forms/healthyforest/signup',
                actionLink2: ''
            })
        }

        if (programs.solarNonprofit.isEnrolled) {
            enrolledPrograms.push({
                program: programConstants.SOLAR_NONPROFIT,
                programText: t('Solar for Nonprofits'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/solar-nonprofits`
                    : `${config.srpnetBaseUrl}account/donate/solar-nonprofits`,
                descriptionText: t("Amount_dollars", {amount: (programs.solarNonprofit.optionValue * 3).toFixed(0)}),
                actionText: t('Change'),
                actionText2: '',
                actionLink: '/forms/solarnonprofit/change',
                actionLink2: ''

            })
        } else if (programs.solarNonprofit.isAvailable) {
            availablePrograms.push({
                program: programConstants.SOLAR_NONPROFIT,
                programText: t('Solar for Nonprofits'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/donacion/solar-nonprofits`
                    : `${config.srpnetBaseUrl}account/donate/solar-nonprofits`,
                descriptionText: t('Help give free solar energy systems to Valley nonprofits'),
                actionText: t('Sign up'),
                actionText2: '',
                actionLink: '/forms/solarnonprofit/signup',
                actionLink2: ''
            })
        }

        if (programs.safetyNet.isEnrolled) {
            // programs.safetyNet.safetyNetsName contains Safety Net partner name.
            // No need to globalize it.
            enrolledPrograms.push({
                program: programConstants.SAFETY_NET,
                programText: t('SRP Safety Net'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/alertas-safety-net`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/safety-net-alerts`,
                descriptionText: <span className="text-capitalize">{programs.safetyNet.safetyNetsName.toLowerCase()}</span>,
                actionText: t('Remove'),
                actionText2: t('Change'),
                actionLink: handleRemoveSafetyNetDialog,
                actionLink2: '/forms/safetynet/change'
            })
        } else if (programs.safetyNet.isAvailable && customerNames.firstName.length > 0) {
            availablePrograms.push({
                program: programConstants.SAFETY_NET,
                programText: t('SRP Safety Net'),
                programLink: isSpanish
                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/alertas-safety-net`
                    : `${config.srpnetBaseUrl}account/electric/manage-bill/safety-net-alerts`,
                descriptionText: t("If a bill becomes past due, we'll let someone know."),
                actionText: t('Add partner'),
                actionText2: '',
                actionLink: '/forms/safetynet/signup',
                actionLink2: ''
            })
        }

        return {
            enrolledPrograms: enrolledPrograms,
            availablePrograms: availablePrograms
        }
    }

    let displayPrograms = getDisplayPrograms(t, hashedAccountForOpower)

    let hasAvailablePrograms = displayPrograms.availablePrograms.length > 0

    return (
        <div className="srp-card-body">
            <div className="d-lg-block d-none">
                <div className="srp-card-details pt-2 pb-0">
                    {
                        displayPrograms.enrolledPrograms.map((program, index) => {
                            let first = index === 0
                            let last = index === displayPrograms.enrolledPrograms.length - 1

                            return (
                                <MyProgramDetails
                                    key={index}
                                    enrolled={true}
                                    first={first}
                                    last={last}
                                    program={program}
                                    t={t}
                                />
                            )
                        })
                    }
                    {
                        numOfEnrolledPrograms === 0 &&
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-3 text-muted pt-2 pb-2">{t("Enrolled Programs")}</div>
                            <div className="col-9 pt-2 pb-2">{t("youre_not_enrolled_in_any_programs")}</div>
                        </div>
                    }

                </div>
                <Divider />
                <div className="srp-card-details pt-2 pb-0">
                    {
                        displayPrograms.availablePrograms.map((program, index) => {
                            let first = index === 0
                            let last = index === displayPrograms.availablePrograms.length - 1

                            return (
                                <MyProgramDetails
                                    key={index}
                                    enrolled={false}
                                    first={first}
                                    last={last}
                                    program={program}
                                    t={t}
                                />
                            )
                        })
                    }
                    {
                        !hasAvailablePrograms &&
                        <div className="row" style={{ display: 'flex' }}>
                            <div className="col-3 pt-2 pb-2" style={{ color: 'rgb(128, 34, 95)' }}>{t("Available Programs")}</div>
                            <div className="col-9 pt-2 pb-2">{t("youre_enrolled_in_all_programs")}</div>
                        </div>
                    }
                </div>
                <Divider />
                <div className="srp-card-details pb-0" />
            </div>
            <div className="d-lg-none d-block">
                <div className="srp-card-details text-muted font-weight-bold pb-0">{t("Enrolled Programs")}</div>
                {
                    displayPrograms.enrolledPrograms.map((program, index) => {
                        let last = index === displayPrograms.enrolledPrograms.length - 1

                        return (
                            <MyProgramDetailsResponsive
                                key={index}
                                last={last}
                                program={program}
                                enrolled={true}
                                t={t}
                            />
                        )
                    })
                }
                {
                    numOfEnrolledPrograms === 0 &&
                    <div className="srp-card-details pt-2 pb-2 row" style={{ alignItems: 'center' }}>
                        <div className="col align-middle ml-3">{t("youre_not_enrolled_in_any_programs")}</div>
                    </div>
                }

                <Divider />

                <div className="srp-card-details font-weight-bold pb-0" style={{ color: 'rgb(128, 34, 95)' }}>{t("Available Programs")}</div>
                {
                    displayPrograms.availablePrograms.map((program, index) => {
                        let last = index === displayPrograms.availablePrograms.length - 1

                        return (
                            <MyProgramDetailsResponsive
                                key={index}
                                last={last}
                                program={program}
                                enrolled={false}
                                t={t}
                            />
                        )
                    })
                }
                {
                    !hasAvailablePrograms &&
                    <div className="srp-card-details pt-2 pb-2 row" style={{ alignItems: 'center' }}>
                        <div className="col align-middle ml-3">{t("youre_enrolled_in_all_programs")}</div>
                    </div>
                }
                <Divider />
                <div className="srp-card-details pb-0" />
            </div>

            {/* DIALOG FOR REMOVING SAFETY NET PARTNER */}
            <DeleteSafetyNetDialog
                showDialog={showRemoveSafetyNetDialog}
                handleDialog={handleRemoveSafetyNetDialog} />
        </div>
    )
}

MyPrograms.propTypes = {
    programs: PropTypes.object,
    hasCommunitySolar: PropTypes.bool,
    hasEnergyScorecard: PropTypes.bool,
    numOfEnrolledPrograms: PropTypes.number,
    autopayInfo: PropTypes.object,
    budgetBillingAmount: PropTypes.number,
    isBudgetBillingActive: PropTypes.bool,
    customDueDay: PropTypes.number,
    isCustomDueDateEligible: PropTypes.bool,
    isHomeEnergyProfileAvailable: PropTypes.bool,
    solarChoicePercentage: PropTypes.number,
    sharePledge: PropTypes.number,
    isBudgetBillingEligible: PropTypes.bool,
    customerNames: PropTypes.object,
    selectedBillAccountDetails: PropTypes.object,
    handleRemoveSafetyNetDialog: PropTypes.func,
    showRemoveSafetyNetDialog: PropTypes.bool,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    hashedAccountForOpower: PropTypes.string.isRequired,
    singleBillAccount: PropTypes.bool.isRequired,
}

export default MyPrograms