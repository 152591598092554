import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { myAccountConstants } from '../../../constants/myaccount-constants'

const NeedHelpSidebar = ({ setFormLanguage, t, i18n }) => {
    let customerServicePhone = i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER
        let fmttdcustomerServicePhone = i18n.language === "es"
            ? myAccountConstants.SPANISH_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD
            : myAccountConstants.RESIDENTIAL_CUSTOMER_SERVICE_PHONE_NUMBER_FMTTD

    return (
        <div className="col-lg-6 col-12 mt-lg-0 mt-4">
            <div className="srp-card-header">{t("Need help?")}</div>
            <div className="srp-card-body srp-orange-background">
                <div className="srp-card-details">
                    <p className="d-lg-block d-none mb-4">
                        {`${t("For questions please contact SRP Residential Customer Services at")} ${fmttdcustomerServicePhone}${t("available to serve you 24 hours a day, seven days a week")}.`}
                    </p>
                    <p className="d-lg-none d-block mb-4">
                        {`${t("For questions please contact SRP Residential Customer Services at")} `}
                        <a href={"tel:" + customerServicePhone}>
                            {fmttdcustomerServicePhone}
                        </a>
                        {`${t("available to serve you 24 hours a day, seven days a week")}.`}
                    </p>
                    {/* <div>
                        {`${t("This form is also available in Spanish")} `}
                        <button
                            className="displayAsLink"
                            onClick={() => setFormLanguage()}>{t("Available in different language")}</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
NeedHelpSidebar.propTypes = {
    setFormLanguage: PropTypes.func,
    t: PropTypes.func,
    i18n: PropTypes.object
}

export default withTranslation('authorizedParty')(NeedHelpSidebar)