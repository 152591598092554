import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'react-i18next'

import transferModule from '../../../srp_modules/forms/transfer-module'
import { formatDayWithOrdinal } from '../../../srp_modules/utils'

import { formConstants } from '../../../constants/form-constants'

import { displayRateCode } from './../../../srp_modules/forms/display-mapper'

const AboutNewAccountDesktop = ({selectedPlan, selectedSolarChoicePlan,
    dayOfMonth, isPaperlessBilling, email,
    address1, address2, city, isMailingAddressSame,
    mailingAddress1, mailingAddress2,
    mailingCity, mailingState, mailingZipCode,
    mailingCountry, t
    }) => (
    <div className="d-lg-block d-none mb-4">
        <div>
            <span className="font-weight-bold mr-2">{t("price_plan")}</span>
            <span>{displayRateCode(selectedPlan, t)}</span>
        </div>

        {selectedPlan !== formConstants.MPOWER &&
        <div>
            {selectedSolarChoicePlan !== formConstants.SOLARCHOICE_NONE &&
            <div>
                <span className="font-weight-bold mr-2">{t("solarchoice")}</span>
                <span>{transferModule.getSolarChoiceDisplay(selectedSolarChoicePlan)}</span>
            </div>
            }
            <div>
                <span className="font-weight-bold mr-2">{t("billing_due_date")}</span>
                <span>{formatDayWithOrdinal(dayOfMonth) + " " + t("day of every month.")}</span>
            </div>
            <div>
                <span className="font-weight-bold mr-2">{t("paperless_billing")}</span>
                <span>
                {isPaperlessBilling
                ? <Trans i18nKey="bill_will_be_sent_to" t={t}>
                    Active- Billing notifications will be sent to <span className="font-weight-bold">{{email}}</span>
                  </Trans>
                : t("Inactive")
                }
              </span>
            </div>
        </div>
        }

        <span className="font-weight-bold mr-2">{t("mailing_address")}</span>
        <span>
        {isMailingAddressSame
        ? address2.length === 0
            ? `${address1}, ${city}, AZ`
            : `${address1} ${address2}, ${city}, AZ`
        : mailingAddress2.length === 0
            ? `${mailingAddress1}, ${mailingCity},
                ${mailingState} ${mailingZipCode} ${mailingCountry}`
            : `${mailingAddress1} ${mailingAddress2},
                ${mailingCity}, ${mailingState} ${mailingZipCode}
                ${mailingCountry}`
        }
        </span>
    </div>
)

AboutNewAccountDesktop.propTypes = {
    selectedPlan: PropTypes.string.isRequired,
    selectedSolarChoicePlan: PropTypes.string.isRequired,
    dayOfMonth: PropTypes.number.isRequired,
    isPaperlessBilling: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    isMailingAddressSame: PropTypes.bool.isRequired,
    mailingAddress1: PropTypes.string.isRequired,
    mailingAddress2: PropTypes.string.isRequired,
    mailingCity: PropTypes.string.isRequired,
    mailingState: PropTypes.string.isRequired,
    mailingZipCode: PropTypes.string.isRequired,
    mailingCountry: PropTypes.string.isRequired,
    t: PropTypes.func,
    Trans: PropTypes.func
}

export default withTranslation('startService')(AboutNewAccountDesktop)