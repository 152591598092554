import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'
import MenuItem from '@mui/material/MenuItem'
import '../../../styles/price-plan.css'

import { formConstants } from '../../../constants/form-constants'
import validate from '../../../srp_modules/validate'

const AboutYou = ({ activeStep, setFormStep, customerInfo, setCustomerInfoOnChange, t }) => {
    return (
        <Formik
            enableReinitialize={false}
            initialValues={{
                firstName: customerInfo.firstName,
                middleName: customerInfo.middleName,
                lastName: customerInfo.lastName,
                identificationType: customerInfo.identificationType,
                identificationNum: customerInfo.identificationNum,
                emailAddress: customerInfo.emailAddress,
                confirmEmail: customerInfo.confirmEmail,
                phoneNumber: customerInfo.phoneNumber,
                confirmPhone: customerInfo.confirmPhone
            }}
            validate={(values) => validateForm(values, setCustomerInfoOnChange, t)}
            onSubmit={(values) => {
                let transformedValues = transformValues(values)
                setCustomerInfoOnChange(transformedValues)
                setFormStep(activeStep + 1)
            }}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => {
                let identificationNumError = touched.identificationNum && errors.identificationNum !== undefined
                let invalidIdentificationNumError = touched.identificationNum && errors.invalidIdentificationNum !== undefined
                let emailError = touched.emailAddress && errors.email !== undefined
                let invalidEmailError = touched.emailAddress && errors.invalidEmail !== undefined
                let confirmEmailError = touched.confirmEmail && errors.confirmEmail !== undefined
                let invalidConfirmEmailError = touched.confirmEmail && errors.invalidConfirmEmail !== undefined
                let phoneError = touched.phoneNumber && errors.phone !== undefined
                let invalidPhoneError = touched.phoneNumber && errors.invalidPhone !== undefined
                let confirmPhoneError = touched.confirmPhone && errors.confirmPhone !== undefined
                let invalidConfirmPhoneError = touched.confirmPhone && errors.invalidConfirmPhone !== undefined

                let setIdNumOnChange = id => {
                    if (id.match("^[ A-Za-z0-9'-]*$")) {
                        setFieldValue('identificationNum', id, true)
                    }
                }

                let onIdTypeChange = type => {
                    if (values.identificationType !== type) {
                        setFieldValue('identificationNum', "", false)
                        setFieldTouched("identificationNum", false)
                    }

                    setFieldValue('identificationType', type, true)
                }

                return (
                    <form
                        className="mb-2 mt-2 text-muted"
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="firstName"
                            value={values.firstName}
                            disabled
                            label={t("First name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="middleName"
                            value={values.middleName}
                            disabled
                            label={t("Middle name")}
                            InputProps={{
                                startAdornment : values.middleName.length === 0 ? <div /> : null
                            }}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="lastName"
                            value={values.lastName}
                            disabled
                            label={t("Last name")}
                        />
                        <TextField
                            select
                            fullWidth
                            className="mb-3"
                            name="identificationType"
                            value={values.identificationType}
                            onChange={(e) => onIdTypeChange(e.target.value)}
                            label={t("Identification type")}
                        >
                            <MenuItem value={formConstants.SOCIAL}>{t("Social Security #")}</MenuItem>
                            <MenuItem value={formConstants.DRIVERS_LICENSE}>{t("Driver's License")}</MenuItem>
                            <MenuItem value={formConstants.STATE_ID}>{t("State ID")}</MenuItem>
                            <MenuItem value={formConstants.GOVERNMENT_ID}>{t("Government ID")}</MenuItem>
                            <MenuItem value={formConstants.MILITARY_ID}>{t("Military ID")}</MenuItem>
                            <MenuItem value={formConstants.RESIDENCY_ID}>{t("US Residency ID")}</MenuItem>
                            <MenuItem value={formConstants.NATURALIZATION}>{t("Certificate of Naturalization")}</MenuItem>
                            <MenuItem value={formConstants.PASSPORT}>{t("Passport")}</MenuItem>
                            <MenuItem value={formConstants.MEXICAN_VOTER}>{t("Mexico voter ID")}</MenuItem>
                            <MenuItem value={formConstants.TEMP_WORK_AUTH}>{t("Temporary work auth")}</MenuItem>
                            <MenuItem value={formConstants.ITIN}>{t("ITIN")}</MenuItem>
                        </TextField>
                        {values.identificationType === formConstants.SOCIAL || values.identificationType === formConstants.ITIN
                            ? <PatternFormat
                                name="identificationNum"
                                id="identificationNum"
                                className="mb-3"
                                autoComplete="off"
                                value={values.identificationNum}
                                fullWidth={true}
                                type="tel"
                                onValueChange={(values) => setFieldValue('identificationNum', values.formattedValue, true)}
                                onBlur={() => setFieldTouched("identificationNum", true)}
                                format="###-##-####"
                                customInput={TextField}
                                helperText={
                                    identificationNumError
                                        ? t(errors.identificationNum)
                                        : invalidIdentificationNumError
                                            ? t(errors.invalidIdentificationNum)
                                            : ''}
                                error={identificationNumError || invalidIdentificationNumError}
                                label={t("Identification number")}
                            />
                            : <TextField
                                fullWidth
                                className="mb-3"
                                name="identificationNum"
                                id="identificationNum"
                                inputProps={{ maxLength: 30 }}
                                autoComplete="off"
                                value={values.identificationNum}
                                onChange={(e) => setIdNumOnChange(e.target.value)}
                                onBlur={handleBlur}
                                helperText={identificationNumError ? t(errors.identificationNum) : ''}
                                error={identificationNumError}
                                label={t("Identification number")}
                            />
                        }
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="emailAddress"
                            inputProps={{ maxLength: 100 }}
                            value={values.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                emailError
                                    ? t(errors.email)
                                    : invalidEmailError
                                        ? t(errors.invalidEmail)
                                        : ''}
                            error={emailError || invalidEmailError}
                            label={t("Email address")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="confirmEmail"
                            inputProps={{ maxLength: 100 }}
                            value={values.confirmEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                confirmEmailError
                                    ? t(errors.confirmEmail)
                                    : invalidConfirmEmailError
                                        ? t(errors.invalidConfirmEmail)
                                        : ''}
                            error={confirmEmailError || invalidConfirmEmailError}
                            label={t("Confirm email address")}
                        />
                        <PatternFormat
                            name="phoneNumber"
                            id="phoneNumber"
                            className="mb-3"
                            value={values.phoneNumber}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('phoneNumber', values.value, true)}
                            onBlur={() => setFieldTouched("phoneNumber", true)}
                            format="(###) ###-####"
                            customInput={TextField}
                            helperText={
                                phoneError
                                    ? t(errors.phone)
                                    : invalidPhoneError
                                        ? t(errors.invalidPhone)
                                        : ''}
                            error={phoneError || invalidPhoneError}
                            label={t("Phone number")}
                        />
                        <PatternFormat
                            name="confirmPhone"
                            id="confirmPhone"
                            className="mb-2"
                            value={values.confirmPhone}
                            valueIsNumericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('confirmPhone', values.value, true)}
                            onBlur={() => setFieldTouched("confirmPhone", true)}
                            format="(###) ###-####"
                            customInput={TextField}
                            helperText={
                                confirmPhoneError
                                    ? t(errors.confirmPhone)
                                    : invalidConfirmPhoneError
                                        ? t(errors.invalidConfirmPhone)
                                        : ''}
                            error={confirmPhoneError || invalidConfirmPhoneError}
                            label={t("Confirm phone number")}
                        />

                        <div className="d-flex flex-wrap justify-content-end mt-4">
                            <button
                                className="btn srp-btn btn-lightblue ml-2"
                                type="button"
                                onClick={() => setFormStep(activeStep - 1)}>
                                {t("Previous")}
                                </button>
                            <button className="btn srp-btn btn-blue ml-2" type="submit">{t("Next")}</button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

AboutYou.propTypes = {
    activeStep: PropTypes.number,
    setFormStep: PropTypes.func,

    customerInfo: PropTypes.object,
    setCustomerInfoOnChange: PropTypes.func,
    t: PropTypes.func
}

export default withTranslation('transferService')(AboutYou)

function transformValues(values) {
    return {
        ...values,
        firstName: values.firstName.trim(),
        middleName: values.middleName.trim(),
        lastName: values.lastName.trim(),
        identificationType: values.identificationType.trim(),
        identificationNum: values.identificationNum.trim(),
        emailAddress: values.emailAddress.trim(),
        confirmEmail: values.confirmEmail.trim(),
        phoneNumber: values.phoneNumber.replace(/[() -]/g, ''),
        confirmPhone: values.confirmPhone.replace(/[() -]/g, '')
    }
}

function validateForm(values, setValues) {
    let errors = {}

    if (values.identificationNum === undefined || values.identificationNum.trim().length === 0)
        errors.identificationNum = "Identification number is required"
    if (values.identificationType === formConstants.SOCIAL || values.identificationType === formConstants.ITIN) {
        if (values.identificationNum.trim().length > 0 && values.identificationNum.trim().length < 11)
            errors.invalidIdentificationNum = "Invalid identification number"
    }

    if (values.emailAddress === undefined || values.emailAddress.trim().length === 0)
        errors.email = "Email address is required"
    if (!validate.emailAddress(values.emailAddress))
        errors.invalidEmail = "Invalid email address"

    if (values.confirmEmail === undefined || values.confirmEmail.trim().length === 0)
        errors.confirmEmail = "Confirm email address is required"
    if (values.confirmEmail !== values.emailAddress)
        errors.invalidConfirmEmail = "Emails must match"

    if (values.phoneNumber === undefined || values.phoneNumber.trim().length === 0)
        errors.phone = "Phone number is required"
    if (values.phoneNumber.trim().length > 0 && values.phoneNumber.trim().length < 10)
        errors.invalidPhone = "Phone must have 10 digits"

    if (values.confirmPhone === undefined || values.confirmPhone.trim().length === 0)
        errors.confirmPhone = "Confirm phone number is required"
    if (values.confirmPhone !== values.phoneNumber)
        errors.invalidConfirmPhone = "Phone numbers must match"

    let customerInfo = {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        identificationType: values.identificationType,
        identificationNum: values.identificationNum,
        emailAddress: values.emailAddress,
        confirmEmail: values.confirmEmail,
        phoneNumber: values.phoneNumber,
        confirmPhone: values.confirmPhone
    }

    setValues(customerInfo)

    return errors
}
