import React from "react"
import PropTypes from 'prop-types'

import EmailCard from './email-card'
import PhoneCard from './phone-card'

import MailingAddressCard from './mailing-address-card'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Card from '@mui/material/Card'

import EmailBlackIcon from '../../../../images/nav/nav_icons/black_icons/email-black.svg'
import EmailBlueIcon from '../../../../images/nav/nav_icons/blue_icons/email-blue.svg'

import PhoneBlackIcon from '../../../../images/nav/nav_icons/black_icons/phone-black.svg'
import PhoneBlueIcon from '../../../../images/nav/nav_icons/blue_icons/phone-blue.svg'

import HomeBlackIcon from '../../../../images/nav/nav_icons/black_icons/home-black.svg'
import HomeBlueIcon from '../../../../images/nav/nav_icons/blue_icons/home-blue.svg'

class Contacts extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTab: 0,
            openItem: ''
        }

        this.switchTab = this.switchTab.bind(this)
        this.setOpenItem = this.setOpenItem.bind(this)
        this.closeAll = this.closeAll.bind(this)
    }

    switchTab(_event, value) {
        this.setState({
            selectedTab: value
        })
    }

    closeAll() {
        this.setState({
            openItem: ''
        })
    }

    setOpenItem(itemKey) {
        this.setState({
            openItem: itemKey
        })
    }

    render() {
        let emailIcon = EmailBlackIcon
        let phoneIcon = PhoneBlackIcon
        let homeIcon = HomeBlackIcon

        switch (this.state.selectedTab) {
            case 0:
                emailIcon = EmailBlackIcon
                phoneIcon = PhoneBlueIcon
                homeIcon = HomeBlueIcon
                break
            case 1:
                emailIcon = EmailBlueIcon
                phoneIcon = PhoneBlackIcon
                homeIcon = HomeBlueIcon
                break
            case 2:
                emailIcon = EmailBlueIcon
                phoneIcon = PhoneBlueIcon
                homeIcon = HomeBlackIcon
                break
        }

        const mailingAddress = {
            streetAddress: this.props.mailingAddress.streetAddress,
            city: this.props.mailingAddress.city,
            state: this.props.mailingAddress.state,
            zipcode: this.props.mailingAddress.zipcode,
        }

        return (
            <div>
                {/* desktop view */}
                <div className="d-none d-lg-block">
                    <div className="srp-card-header">
                        {this.props.t("My contact information")}
                    </div>
                    <Card>
                        <div className="container">
                            <div className="srp-card-details">
                                <div>
                                    <EmailCard
                                        billAccount={this.props.billAccount}
                                        emails={this.props.emails}
                                        addContactsClick={this.props.addContactsClick}
                                        addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                                        updateContactClick={this.props.updateContactClick}
                                        makePrimaryClick={this.props.makePrimaryClick}
                                        updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                        deleteContactClick={this.props.deleteContactClick}
                                        alertInfo={this.props.alertInfo}
                                        hideAlert={this.props.hideAlert}
                                        subscribedContacts={this.props.subscribedContacts}
                                        openItem={this.state.openItem}
                                        setOpenItem={this.setOpenItem}
                                        t={this.props.t}
                                    />
                                    <div style={{position: "absolute", borderBottom: "1px solid rgba(204,204,204,1)", left: "14px", width: "93%", paddingTop: "15px"}} />
                                </div>
                                <div style={{paddingTop: "40px"}}>
                                    <PhoneCard
                                        billAccount={this.props.billAccount}
                                        phoneNumbers={this.props.phoneNumbers}
                                        addContactsClick={this.props.addContactsClick}
                                        addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                                        updateContactClick={this.props.updateContactClick}
                                        makePrimaryClick={this.props.makePrimaryClick}
                                        updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                        deleteContactClick={this.props.deleteContactClick}
                                        alertInfo={this.props.alertInfo}
                                        hideAlert={this.props.hideAlert}
                                        subscribedContacts={this.props.subscribedContacts}
                                        openItem={this.state.openItem}
                                        setOpenItem={this.setOpenItem}
                                        t={this.props.t}
                                    />
                                    <div style={{position: "absolute", borderBottom: "1px solid rgba(204,204,204,1)", left: "14px", width: "93%", paddingTop: "15px"}} />
                                </div>
                                <div style={{paddingTop: "40px"}}>
                                    <MailingAddressCard mailingAddress={mailingAddress} t={this.props.t}/>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                {/* mobile view */}
                <div className="d-block d-lg-none">
                    <div className="srp-card-header">
                        {this.props.t("My contact information")}
                    </div>
                    <Card>
                        <Tabs
                            value={this.state.selectedTab}
                            onChange={this.switchTab}
                            indicatorColor="primary"
                            textColor="primary"
                            className="srp-icon-color"
                            style={{ color: 'black' }}
                            variant="fullWidth"
                        >
                            <Tab
                                className="mui_tab"
                                icon={<img src={emailIcon} style={{height:"23px", width:"23px"}} />}
                                label="Email"
                                style={{ outline: "none", boxShadow: "none", textTransform: "capitalize", paddingTop: "20px" }}
                            />
                            <Tab
                                className="mui_tab"
                                icon={<img src={phoneIcon} style={{height:"23px", width:"23px"}} />}
                                label="Phone"
                                style={{ outline: "none", boxShadow: "none", textTransform: "capitalize", paddingTop: "20px" }}  />
                            <Tab
                                className="mui_tab"
                                icon={<img src={homeIcon} style={{height:"23px", width:"23px"}} />}
                                label="Mailing"
                                style={{ outline: "none", boxShadow: "none", textTransform: "capitalize", paddingTop: "20px" }}  />
                        </Tabs>
                        <div className="srp-card-details">
                            {this.state.selectedTab === 0  && <EmailCard
                                billAccount={this.props.billAccount}
                                emails={this.props.emails}
                                addContactsClick={this.props.addContactsClick}
                                addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                                updateContactClick={this.props.updateContactClick}
                                makePrimaryClick={this.props.makePrimaryClick}
                                updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                deleteContactClick={this.props.deleteContactClick}
                                alertInfo={this.props.alertInfo}
                                hideAlert={this.props.hideAlert}
                                subscribedContacts={this.props.subscribedContacts}
                                openItem={this.state.openItem}
                                setOpenItem={this.setOpenItem}
                                t={this.props.t}
                            />}
                            {this.state.selectedTab === 1 && <PhoneCard
                                billAccount={this.props.billAccount}
                                phoneNumbers={this.props.phoneNumbers}
                                addContactsClick={this.props.addContactsClick}
                                addContactsAndCopySubscriptionsClick={this.props.addContactsAndCopySubscriptionsClick}
                                updateContactClick={this.props.updateContactClick}
                                makePrimaryClick={this.props.makePrimaryClick}
                                updateContactAndMakePrimaryClick={this.props.updateContactAndMakePrimaryClick}
                                deleteContactClick={this.props.deleteContactClick}
                                alertInfo={this.props.alertInfo}
                                hideAlert={this.props.hideAlert}
                                subscribedContacts={this.props.subscribedContacts}
                                openItem={this.state.openItem}
                                setOpenItem={this.setOpenItem}
                                t={this.props.t}
                            />}
                            {this.state.selectedTab === 2 && <div className="pb-3">
                                <MailingAddressCard mailingAddress={mailingAddress} />
                            </div>}
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}

Contacts.propTypes = {
    billAccount: PropTypes.number.isRequired,
    mailingAddress: PropTypes.shape({
        streetAddress: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zipcode: PropTypes.string.isRequired,
    }).isRequired,
    emails: PropTypes.arrayOf(PropTypes.object).isRequired,
    phoneNumbers: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryEmail: PropTypes.string.isRequired,
    addContactsClick: PropTypes.func.isRequired,
    addContactsAndCopySubscriptionsClick: PropTypes.func.isRequired,
    updateContactClick: PropTypes.func.isRequired,
    makePrimaryClick: PropTypes.func.isRequired,
    updateContactAndMakePrimaryClick: PropTypes.func.isRequired,
    deleteContactClick: PropTypes.func.isRequired,
    subscribedContacts: PropTypes.object.isRequired,
    alertInfo: PropTypes.object.isRequired,
    hideAlert: PropTypes.func.isRequired,
    t: PropTypes.func
}

export default Contacts