import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit'

import { apiMiddleware } from 'redux-api-middleware'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers'
import { authRedirectMiddleware } from '../srp_modules/middleware/auth-redirect-middleware'
import { billAccountMiddleware } from '../srp_modules/middleware/bill-account-middleware'
import { myAccountHeaderMiddleware } from '../srp_modules/middleware/my-account-header-middleware'
import { xsrfMiddleware } from '../srp_modules/middleware/xsrf-middleware'
import { apiDebounceOptionsMiddleware } from '../srp_modules/middleware/api-debounce-options-middleware'

import { publicConfigApi } from '../slices/public-config-slice'

function configureStore(initialState, history) {
    const middlewares = [
        createRouterMiddleware(history),
        // Add other middleware on this line...
        xsrfMiddleware,
        myAccountHeaderMiddleware,
        apiDebounceOptionsMiddleware,
        createDebounce(),
        billAccountMiddleware(apiMiddleware),
        authRedirectMiddleware,

        publicConfigApi.middleware
    ]

    let store = reduxConfigureStore({
        reducer: rootReducer(history),
        initialState,
        middleware: getDefaultMiddleware => {
            return getDefaultMiddleware({
                serializableCheck: false
            }).concat(middlewares)
        }
    })

    if (module.hot && process.env.NODE_ENV !== 'production') {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        })
    }

    return store
}

export default configureStore