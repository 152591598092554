import React, { useState } from 'react'
import config from 'my-account-config'
import { Icon } from '@mui/material'
import { DateTime } from 'luxon'
import { useGetConfigInfoQuery } from '../slices/public-config-slice'
import { useTranslation } from 'react-i18next'

const EmergencyBanner = () => {
    let [ isDismissed, dismiss ] = useState(false)
    let { data, isSuccess, endpointName } = useGetConfigInfoQuery()
    let { t } = useTranslation(endpointName)

    if (!data || !data.emergencyBanner)
        return

    let bannerInfo = data.emergencyBanner
    let now = DateTime.now().setZone('MST')
    let start = DateTime.fromISO(bannerInfo.startDate)
    let end = DateTime.fromISO(bannerInfo.endDate)
    let hideBanner = now < start || now > end

    return (isDismissed || hideBanner) && isSuccess ? null : (
    <div id="srp-emergency-alert">
        <div className="container">
            <div className="row">
                <div className="col-8 offset-2">
                    <div className="row">
                        <div className="d-none d-md-flex col-3">
                            <img src={`${config.srpnetBaseUrl}/assets/srpnet/components/Alert_Icon.svg`} className="mx-auto" alt="" />
                        </div>
                        <div className="col-12 col-md-9">
                            <p className="mt-0 fw-600">
                                {t(bannerInfo.title)}
                            </p>
                            <p className="mb-0">
                                {t(bannerInfo.message)}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className="d-flex flex-column align-items-end">
                            <Icon role="button" onClick={() => dismiss(true)}>close</Icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EmergencyBanner