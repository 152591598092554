import React from 'react'
import { connect } from 'react-redux'
import { DocumentTitle } from '../srp_modules/document-title'
import PropTypes from 'prop-types'
import cookies from 'js-cookie'
import queryString from 'query-string'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

import SiteOffline from './site-offline'
import InitialLoadingPage from './initial-loading-page'

import { loginProfile } from '../actions/auth/login/login-actions'
import { savePreferredLanguage } from '../actions/auth/myaccount/myaccount-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_SUCCESS } from '../constants/loading-status-constants'
import { pageLoadEvent, viewChangedEvent } from '../srp_modules/analytics-events'
import { getXsrfToken } from '../srp_modules/middleware/xsrf-middleware'
import { withRouter } from '../srp_modules/with-router'
import EmergencyBanner from './emergency-banner'

const muiTheme = createTheme({
    palette: { error: { light: "#9E2A2B", main: "#9E2A2B", dark: "#9E2A2B" } },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.nav-button-responsive': {
                        color: 'rgba(0, 0, 0, 0.87)',
                        backgroundColor: 'white',
                        fontSize: '1rem'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: { color: '#087ED8' },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#087ED8'
                    },
                    '&.Mui-disabled': {
                        color: 'rgba(8, 126, 216, 0.376)'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides:{
                root: {
                    color: grey[600],
                    backgroundColor: '#E2E7EF',
                    fontFamily: 'futura-pt',
                    fontSize: '1rem'
                },
                deletable: {
                    '&:focus': {
                        backgroundColor: '#E2E7EF'
                    }
                },
                deleteIcon: { color: grey[600] },
                colorPrimary : {
                    color: '#ffffff'
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: '#004B87'
                },
                colorSecondary: {
                    color: '#FFFFFF'
                }
            }
        },
        MuiDateField: {
            defaultProps: {
                shouldRespectLeadingZeros: true
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: 'rgba(8, 126, 216, 0.376)'
                    },
                    '&:focus': {
                        outline: 'none'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(63, 81, 181, 0.08);'
                    }
                },
                colorPrimary: { color: '#087ED8' }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    fontFamily: 'futura-pt'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: '#087ED8'
                    }
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: '#087ED8',
                        '&:hover': {
                            backgroundColor: '#087ED8'
                        },
                        '&:focus': {
                            backgroundColor: '#087ED8',
                            outline: 'none'
                        }
                    },
                },
                day: {
                    '&:hover': {
                        backgroundColor: '#e6f2fb'
                    },
                    '&:focus': {
                        backgroundColor: '#a2cef0',
                        outline: 'none'
                    }
                },
                current: {
                    border: '1px solid #087ED8'
                }
            }
        },
        MuiPickersModal: {
            styleOverrides: {
                dialogAction: {
                    color: '#087ED8',
                    '&:focus': {
                        outline: 'none'
                    }
                },
            }
        },
        MuiPickersToolbar: {
            styleOverrides: {
                toolbar: { backgroundColor: '#087ED8' }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: { color: '#087ED8' },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#087ED8'
                    },
                    '&.Mui-disabled': {
                        color: '#087ED8'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: { color: '#087ED8', margin: '8px auto' },
                icon: { color: '#087ED8' }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: '#6787B7',
                    '&.Mui-active': {
                        color: '#004B87'
                    },
                    '&.Mui-completed': {
                        color: '#6787B7'
                    }
                },
                text: {
                    fontSize: '0.75rem',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    fontSize: '1rem',
                    color: '#087ED8',
                    '&.Mui-selected': {
                        color: 'black'
                    }
                }
            }
        },
        MuiTabIndicator: {
            styleOverrides: {
                colorPrimary: { backgroundColor: '#087ED8' }
            }
        },
        MuiTableBody : {
            styleOverrides : {
                root : {
                    '& .MuiTableCell-root': {
                        fontSize: '15px',
                        height: '48px'
                    }
                }
            }
        },
        MuiTableFooter : {
            styleOverrides: {
                root : {
                    '& .MuiTableCell-root': {
                        borderBottom: 'none',
                        fontSize: '15px',
                        height: '48px'
                    }
                }
            }
        },
        MuiTableHead : {
            styleOverrides : {
                root : {
                    '& .MuiTableCell-root': {
                        fontSize: '14px',
                        height: '48px',
                        color: grey[700],
                        fontWeight: '600'
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: { backgroundColor: '#087ED8' }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: '8px auto',
                    '& .MuiSelect-select' : {color : '#087ED8'}
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'rgb(84,110,122)',
                    borderRadius: '10px',
                    maxWidth: '230px',
                    textAlign: 'center',
                    fontSize: '13px',
                    padding: '8px 21px 8px 21px'
                },
                popper: {
                    opacity: '1'
                }
            }
        },
        MuiStepper: {
            styleOverrides : {
                root : {
                    '& .MuiStepLabel-label': {
                        fontSize : '16px'
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides : {
                root : {
                    '& .MuiAccordionSummary-expandIconWrapper' : {
                        position : 'absolute',
                        right: '8px'
                    }
                }
            }
        }
    },
    typography: {
        fontFamily: 'futura-pt',
        htmlFontSize: 16
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        }
    }
})

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
class App extends React.Component {
    constructor(props) {
        super(props)

        let params = queryString.parse(location.search)
        this.checkMaintenanceTestMode(params)
        props.checkPageLanguageQueryString(params)

        this.state = {
            pageLoadReported: false,
            firstPageLoad: true
        }
    }

    componentDidMount() {
        this.props.checkLogin()
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (this.props.router.location !== nextProps.router.location && this.state.pageLoadReported && nextState.pageLoadReported) {
            this.setState({ pageLoadReported: false })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isLoggedIn && this.props.xsrfToken === '' && this.props.xsrfStatus !== LOADING_STATUS_IN_PROGRESS) {
            this.props.getXsrfToken()
        }

        if (this.props.loginProfileStatus === LOADING_STATUS_SUCCESS
            && this.props.router.location.pathname === '/'
            && this.props.isLoggedIn
            && this.props.xsrfToken !== '') {

            this.props.router.navigate('/myaccount/dashboard', { replace: true })
            // Return to prevent page load from being reported, it will get reported during a subsequent render
            return
        }

        if (this.viewHasLoaded(this.props) && !this.waitForBillAccountToLoad(this.props) && !this.state.pageLoadReported) {
            if (this.state.firstPageLoad) {
                pageLoadEvent(this.props.customerKey, this.props.userAccountId, this.props.userId)
            }
            else {
                viewChangedEvent(this.props.customerKey, this.props.userAccountId, this.props.userId)
            }
            this.setState({ pageLoadReported: true, firstPageLoad: false }) // eslint-disable-line react/no-did-update-set-state
        }

        if (prevProps.router.location !== this.props.router.location) {
            window.scrollTo(0, 0)
        }
    }

    checkMaintenanceTestMode(params) {
        if (params && params.mode === 'maintenanceTest' && cookies.get('maintenancetest') === undefined) {
            cookies.set('maintenancetest', 'true', { secure: true, sameSite: 'None' })
        }
    }

    viewHasLoaded(props) {
        return props.loginProfileStatus !== LOADING_STATUS_INIT
            && props.loginProfileStatus !== LOADING_STATUS_IN_PROGRESS
            && (!props.isLoggedIn || props.xsrfToken !== '')
    }

    waitForBillAccountToLoad(props) {
        // I'm using a global variable here to let me know to wait for get bill account list.
        // I figured it would be much less complicated than trying to walk the component tree
        // to find any components expecting to use the bill account.
        return window.pageHasBillAccountList
            && (props.billAccountListStatus === LOADING_STATUS_INIT
                || props.billAccountListStatus === LOADING_STATUS_IN_PROGRESS)
    }

    render() {
        let showPage = this.viewHasLoaded(this.props)

        return showPage ? (
            <DocumentTitle title="SRP Power">
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={muiTheme}>
                        <EmergencyBanner />
                        <div>
                            {this.props.siteOfflineInfo.siteOffline
                             ? <SiteOffline />
                             : this.props.children}
                        </div>
                    </ThemeProvider>
                </StyledEngineProvider>
            </DocumentTitle>
        ) : <InitialLoadingPage />
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.login.isLoggedIn,
        isIrrigator: state.login.isIrrigator,
        userId: state.login.userId,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        customerKey: state.accountInfo.billAccount.selectedBillAccountDetails.customerKey,
        userAccountId: state.accountInfo.billAccount.selectedBillAccountDetails.userAccountId,
        siteOfflineInfo: state.login.siteOfflineInfo,
        loginProfileStatus: state.login.loginProfileStatus,
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        xsrfStatus: state.login.xsrfStatus,
        xsrfToken: state.login.xsrfToken,
        maintenanceTestMode: cookies.get('maintenancetest') !== undefined
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        checkLogin: () => {
            dispatch(loginProfile())
        },
        checkPageLanguageQueryString: params => {
            if (params.lang) {
                dispatch(savePreferredLanguage(params.lang))
                let updatedLocation = `${ownProps.router.location.pathname}${ownProps.router.location.search.replace(/lang=[^&]+&?/i, '')}${ownProps.router.location.hash}`
                ownProps.router.navigate(updatedLocation, { replace: true })
            }
        },
        getXsrfToken: () => {
            dispatch(getXsrfToken())
        }
    }
}

App.propTypes = {
    children: PropTypes.element.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isIrrigator: PropTypes.bool.isRequired,
    loginProfileStatus: PropTypes.string.isRequired,
    myAccountType: PropTypes.string,
    router: PropTypes.shape({
        navigate: PropTypes.func,
        location: PropTypes.object
    }).isRequired,
    selectedBillAccount: PropTypes.number,
    siteOfflineInfo: PropTypes.shape({
        siteOffline: PropTypes.bool,
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        message: PropTypes.string
    }).isRequired,
    userId: PropTypes.string,
    customerKey: PropTypes.number,
    userAccountId: PropTypes.number,
    xsrfStatus: PropTypes.string,
    xsrfToken: PropTypes.string,

    checkLogin: PropTypes.func.isRequired,
    checkPageLanguageQueryString: PropTypes.func.isRequired,
    getXsrfToken: PropTypes.func.isRequired,
    maintenanceTestMode: PropTypes.bool.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))