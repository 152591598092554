import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import { PatternFormat } from 'react-number-format'

import validate from '../../srp_modules/validate'

const AccountDetailsCommercial = ({ activeStep, setActiveStep, accountDetails, setAccountDetails, t }) => {

    return (
        <Formik
            initialValues={{
                billAccount: accountDetails.billAccount,
                firstName: accountDetails.firstName || '',
                middleInitial: accountDetails.middleInitial || '',
                lastName: accountDetails.lastName || '',
                businessName: accountDetails.businessName || '',
                dbaName: accountDetails.dbaName || '',
                contactName: accountDetails.contactName || '',
                contactPosition: accountDetails.contactPosition || '',
                serviceAddressLineOne: accountDetails.serviceAddressLineOne || '',
                serviceAddressLineTwo: accountDetails.serviceAddressLineTwo || '',
                city: accountDetails.city || '',
                zipCode: accountDetails.zipCode,
                email: accountDetails.email || '',
                phone: accountDetails.phone
            }}
            validate={(values) => validateForm(values)}
            onSubmit={(values) => {
                let transformedValues = transformValues(values)
                setAccountDetails(transformedValues)
                setActiveStep(activeStep + 1)
            }}>
            {({ values, errors, touched, dirty, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, submitCount }) => {
                let billAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.billAccount !== undefined
                let invalidBillAccountError = (dirty || submitCount > 0) && touched.billAccount && errors.invalidBillAccount !== undefined
                let businessNameError = (dirty || submitCount > 0) && touched.businessName && errors.businessName !== undefined
                let serviceAddressError = (dirty || submitCount > 0) && touched.serviceAddressLineOne && errors.serviceAddress !== undefined
                let cityError = (dirty || submitCount > 0) && touched.city && errors.city !== undefined
                let zipCodeError = (dirty || submitCount > 0) && touched.zipCode && errors.zipCode !== undefined
                let emailError = (dirty || submitCount > 0) && touched.email && errors.email !== undefined
                let invalidEmailError = (dirty || submitCount > 0) && touched.email && errors.invalidEmail !== undefined
                let phoneError = (dirty || submitCount > 0) && touched.phone && errors.phone !== undefined
                let invalidPhoneError = (dirty || submitCount > 0) && touched.phone && errors.invalidPhone !== undefined

                let setZipCodeOnChange = zipCode => {
                    if (zipCode.match("^[a-zA-Z0-9]*$")) {
                        setFieldValue('zipCode', zipCode, true)
                    }
                }

                return (
                    <form className="mb-2 mt-4 text-muted" onSubmit={handleSubmit}>
                        <PatternFormat
                            id="billAccount"
                            name="billAccount"
                            className="mb-3"
                            value={values.billAccount}
                            valueIsNumbericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('billAccount', values.value, true)}
                            onBlur={() => setFieldTouched("billAccount", true)}
                            format="###-###-###"
                            customInput={TextField}
                            helperText={billAccountError
                                ? t(errors.billAccount)
                                : invalidBillAccountError
                                    ? t(errors.invalidBillAccount)
                                    : ''}
                            error={billAccountError || invalidBillAccountError}
                            label={t("SRP account number")}
                        />

                        <TextField
                            fullWidth
                            className="mb-3"
                            name="businessName"
                            inputProps={{ maxLength: 35 }}
                            value={values.businessName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={businessNameError ? t(errors.businessName) : ''}
                            error={businessNameError}
                            label={t("Business name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="dbaName"
                            inputProps={{ maxLength: 30 }}
                            value={values.dbaName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("DBA name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="contactName"
                            inputProps={{ maxLength: 24 }}
                            value={values.contactName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("Contact name")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="contactPosition"
                            inputProps={{ maxLength: 20 }}
                            value={values.contactPosition}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={t("Contact position")}
                        />
                        <TextField
                            fullWidth
                            className="mb-2"
                            name="serviceAddressLineOne"
                            inputProps={{ maxLength: 39 }}
                            value={values.serviceAddressLineOne}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={serviceAddressError}
                            label={t("Street address")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="serviceAddressLineTwo"
                            inputProps={{ maxLength: 39 }}
                            value={values.serviceAddressLineTwo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={serviceAddressError ? t(errors.serviceAddress) : ''}
                            error={serviceAddressError}
                            label={t("Apt, suite, etc (optional)")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="city"
                            inputProps={{ maxLength: 30 }}
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={cityError ? t(errors.city) : ''}
                            error={cityError}
                            label={t("City")}
                        />
                        <TextField
                            id="zipCode"
                            name="zipCode"
                            className="mb-4"
                            inputProps={{ maxLength: 9 }}
                            value={values.zipCode}
                            fullWidth={true}
                            onChange={(e) => setZipCodeOnChange(e.target.value)}
                            onBlur={handleBlur}
                            helperText={zipCodeError ? t(errors.zipCode) : ''}
                            error={zipCodeError}
                            label={t("Zip code")}
                        />
                        <TextField
                            fullWidth
                            className="mb-3"
                            name="email"
                            inputProps={{ maxLength: 100 }}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                emailError
                                    ? t(errors.email)
                                    : invalidEmailError
                                        ? t(errors.invalidEmail)
                                        : ''}
                            error={emailError || invalidEmailError}
                            label={t("Email address")}
                        />

                        <PatternFormat
                            id="phone"
                            name="phone"
                            className="mb-4"
                            value={values.phone}
                            valueIsNumbericString={true}
                            fullWidth={true}
                            type="tel"
                            onValueChange={(values) => setFieldValue('phone', values.value, true)}
                            onBlur={() => setFieldTouched("phone", true)}
                            format="(###) ###-####"
                            customInput={TextField}
                            helperText={
                                phoneError
                                    ? t(errors.phone)
                                    : invalidPhoneError
                                        ? t(errors.invalidPhone)
                                        : ''}
                            error={phoneError || invalidPhoneError}
                            label={t("Phone number")}
                        />
                        <div className="d-flex flex-wrap justify-content-end">
                            <button className="btn srp-btn btn-blue" type="submit">{t("Next")}</button>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

AccountDetailsCommercial.propTypes = {
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,

    accountDetails: PropTypes.object,
    setAccountDetails: PropTypes.func,

    t: PropTypes.func
}

export default withTranslation('changePricePlan')(AccountDetailsCommercial)

function transformValues(values) {
    return {
        ...values,
        billAccount: values.billAccount.trim(),
        businessName: values.businessName.trim(),
        dbaName: values.dbaName.trim(),
        contactName: values.contactName.trim(),
        contactPosition: values.contactPosition.trim(),
        serviceAddressLineOne: values.serviceAddressLineOne.trim(),
        serviceAddressLineTwo: values.serviceAddressLineTwo.trim(),
        city: values.city.trim(),
        zipCode: values.zipCode.trim(),
        email: values.email.trim(),
        phone: values.phone.replace(/[() -]/g, '')
    }
}

function validateForm(values) {
    let errors = {}
    if (values.billAccount === undefined || values.billAccount.trim().length === 0)
        errors.billAccount = "SRP account number is required"
    if (values.billAccount.length < 9)
        errors.invalidBillAccount = "Invalid SRP account number"

    if (values.businessName === undefined || values.businessName.trim().length === 0)
        errors.businessName = "Business name is required"

    if (values.serviceAddressLineOne === undefined || values.serviceAddressLineOne.trim().length === 0)
        errors.serviceAddress = "Service address is required"

    if (values.city === undefined || values.city.trim().length === 0)
        errors.city = "City is required"

    if (values.zipCode === undefined || values.zipCode.trim().length === 0)
        errors.zipCode = "Zip code is required"

    if (values.email === undefined || values.email.trim().length === 0)
        errors.email = "Email address is required"
    if (!validate.emailAddress(values.email))
        errors.invalidEmail = "Please enter a valid email address"

    if (values.phone === undefined || values.phone.trim().length === 0)
        errors.phone = "Phone number is required"
    if (values.phone.length != 10)
        errors.invalidPhone = "Please enter a valid phone number"

    return errors
}