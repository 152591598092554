/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {formConstants} from '../../../constants/form-constants'
import config from 'my-account-config'

const displayRadioLabel = (heading, message, learnMoreLink, t) => {
  return <span><b>{heading}:</b>{message} <a target="_blank" href={learnMoreLink}>{`${t("learn_more")} »`}</a></span>
}

const RateSelection = ({rateChangeFunc, selectedPlan, toggleSolarMessage, t, i18n}) => {
  const mpower_plan_link = i18n.language==="es"
    ? `https://www.srpnet.com/es/planes-de-precio/residencial-electrico/m-power`
    : `https://www.srpnet.com/price-plans/residential-electric/m-power`

  return (<div>
    <div className="pt-2 mb-lg-4 mb-3 text-muted">
      {t("contact_via_email")}
    </div>
    <div className="pt-2">
      <FormGroup>
        <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.TIME_OF_USE}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.TIME_OF_USE}
                aria-label={`${t("tou_plan")} ${t("tou_plan_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("tou_plan")}`,` ${t("tou_plan_description")} `, t("tou_plan_link"), t)}
        />  
        <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.EZ_3}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.EZ_3}
                aria-label={`${t("ez3_3_6_plan")} ${t("ez3_3_6_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("ez3_3_6_plan")}`,` ${t("ez3_3_6_description")} `,t("ez3_plan_link"), t)}
        />
        <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.EZ_34}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.EZ_34}
                aria-label={`${t("ez3_4_7_plan")} ${t("ez3_4_7_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("ez3_4_7_plan")}`,` ${t("ez3_4_7_description")} `, t("ez3_plan_link"), t)}
        />
        <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.ELECTRIC_VEHICLE}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.ELECTRIC_VEHICLE}
                aria-label={`${t("ev_plan")} ${t("ev_plan_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("ev_plan")}`,` ${t("ev_plan_description")} `, t("ev_plan_link"), t)}
        />
         <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.BASIC}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.BASIC}
                aria-label={`${t("basic_plan")} ${t("basic_plan_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("basic_plan")}`,` ${t("basic_plan_description")} `, t("basic_plan_link"), t)}
        />
        <label className="pl-4 ml-2">{displayRadioLabel(`${t("customer_generation_plan")}`,` ${t("customer_generation_plan_description")} `, t("customer_generation_plan_link"), t)}</label>
        <div className="pl-4 ml-2 mb-2">
            <button className="displayAsLink" onClick={(e) => toggleSolarMessage(e)}>{t("why_cant_sign_up_for_solar")}</button>
        </div>
        <div className="flex-row mb-2">
            <div className="pt-2">
              <i>{t("no_upfront_cost_credit")}</i>
            </div>
        </div>
        <FormControlLabel
            control={<Radio
                checked={selectedPlan === formConstants.MPOWER}
                onChange={event=> rateChangeFunc(event.target.value)}
                value={formConstants.MPOWER}
                aria-label={`${t("mpower_plan")} ${t("mpower_plan_description")}`}
                color="primary"
            />}
            label={displayRadioLabel(`${t("mpower_plan")}`,` ${t("mpower_plan_description")} `, mpower_plan_link, t)}
        />

      </FormGroup>
    </div>
  </div>)
}

RateSelection.propTypes = {
    rateChangeFunc: PropTypes.func,
    selectedPlan: PropTypes.string,
    toggleSolarMessage: PropTypes.func,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func
}

export default withTranslation('startService')(RateSelection)