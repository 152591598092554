import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import config from 'my-account-config'

import CheckMarkIcon from '../../../images/common/SuccessCheck.png'

import { Trans } from 'react-i18next'
import i18n from '../../../i18n'

let abbreviatedDate = (day) => {
    if(i18n.language === 'es') return ''
    switch (day) {
        case 1:
        case 21:
            return 'st'

        case 2:
        case 22:
            return 'nd'

        case 3:
        case 23:
            return 'rd'

        default:
            return 'th'
    }
}

const CustomDueDateSuccess = ({ customerEmailAddress, customDueDateInfo, isLoggedIn, t }) => {

    let buttonSet =
        (<Link to="/MyAccount/dashboard/" className="btn srp-btn btn-lightblue ml-2 mb-2">
            {t("Go to dashboard")}
        </Link>)

    if (!isLoggedIn) {
        buttonSet = (<div>
            <a href={config.srpnetBaseUrl} className="btn srp-btn btn-lightblue ml-2 mb-2">
                {t("Go to srpnet.com")}
            </a>
            <Link to="/accountSetup" className="btn srp-btn btn-lightblue ml-2 mb-2">
                {t("Sign up for My Account")}
            </Link>
        </div>)
    }
    return (
        <div>
            <h3 style={{ color: '#737b4c' }}><img src={CheckMarkIcon} style={{ height: "50px", width: "50px" }} /><span style={{ paddingLeft: ".5em" }}>{t("You're all set!")}</span></h3>
            <div style={{ paddingTop: "10px" }}>
                <Trans i18nKey="request_submitted_emailed_to" t={t}>
                Your request has been submitted. A copy of the request has been sent to <span className="font-weight-bold">{{customerEmailAddress}}</span>
                </Trans>
            </div>
            <div style={{ paddingTop: "15px" }}>{t("SRP_will_process_in_1_day")}</div>
            <h4 style={{ paddingTop: "15px" }} className="text-muted">{t("Custom due date requested")}</h4>
            <div>{t("A_new_due_date_of", {newCustomDueDate: customDueDateInfo.customDueDate + abbreviatedDate(customDueDateInfo.customDueDate), lastSetDateDate: customDueDateInfo.lastSetDateDate, lastSetDateTime: customDueDateInfo.lastSetDateTime})}</div>
            <div className="justify-content-end" style={{ paddingTop: "15px" }}>
                <div className="col-md text-right">
                    {buttonSet}
                </div>
            </div>
        </div>
    )
}

CustomDueDateSuccess.propTypes = {
    customerEmailAddress: PropTypes.string,
    customDueDateInfo: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    t: PropTypes.func.isRequired,
}

export default CustomDueDateSuccess