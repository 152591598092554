import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { Formik } from 'formik'
import { updateBillAccountNickname, addMultipleBillAccounts, getBillAccounts } from '../../../../actions/auth/bill_account/bill-account-actions'
import { addSnackbarMessage } from '../../../../actions/common/snackbar'
import SrpTooltip from '../../../common_tooltip/srp-tooltip'
import SrpPopupMenu from '../../../common_popup_menu/srp-popup-menu'

let getNickName = (accountName, alreadyAdded, fullStreetAddress) => {
    if (accountName != null && accountName.length > 0) {
        return accountName
    } else if (!alreadyAdded) {
        return fullStreetAddress
    } else {
        return ''
    }
}
class SummaryBillingSubAccountResponsive extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showEditNickname: false,
            disableAddAccount: false
        }

        this.updateNickname = this.updateNickname.bind(this)
        this.addAccountToProfile = this.addAccountToProfile.bind(this)
    }

    async updateNickname(values, formikGoodies, t) {
        let updateResponse = await this.props.updateNickname(values)
        formikGoodies.setSubmitting(false)

        if (!updateResponse || updateResponse.error === true || updateResponse.payload.success === false) {
            formikGoodies.setStatus({ error: t("We're sorry, there was an error. Please try again later.") })
        }
        else {
            this.setState({ ...this.state, showEditNickname: false })
        }
    }

    async addAccountToProfile(billAccount, nickname) {
        if (this.state.disableAddAccount) {
            return
        } else {
            this.setState({ ...this.state, disableAddAccount: true})
            await this.props.addAccountToProfile(billAccount, nickname)
            this.setState({ ...this.state, disableAddAccount: false})
        }
    }

    render() {
        let { billAccount, accountName, accountText, isFinalAccount, alreadyAdded, serviceAddress } = this.props.subAccount
        let disableEditAndDelete = this.props.disableEditAndDelete
        let rowClass = alreadyAdded ? 'summary-billing-subaccount-row-responsive-account-name' : 'summary-billing-subaccount-row-not-added-responsive'
        let nickName = getNickName(accountName, alreadyAdded, serviceAddress.fullStreetAddress)

        return (
            <div>
                {this.state.showEditNickname
                    ? <Formik
                        onSubmit={async (values, formikGoodies) => await this.updateNickname(values, formikGoodies, this.props.t)}
                        initialValues={{
                            billAccount: billAccount,
                            nickname: accountName || ''
                        }}>
                        {({ values, status, handleBlur, handleChange, handleSubmit, isSubmitting }) => {
                            let formStatus = status || {}

                            return (
                                <form className="border-bottom p-2" onSubmit={handleSubmit}>
                                    {formStatus.error ?
                                        <div className="row mt-2 mb-2">
                                            <div className="col">
                                                <span className="srp-alert-error">{formStatus.error}</span>
                                            </div>
                                        </div> : null}
                                    <div className="row">
                                        <div className="col">
                                            {/* <InputLabel>SRP account number:</InputLabel> */}
                                            <TextField value={accountText} fullWidth={true} disabled={true} label="SRP account number"/>
                                            {/* <InputLabel htmlFor="nickname" className="pt-2">Nickname</InputLabel> */}
                                            <TextField
                                                autoFocus={true}
                                                name="nickname"
                                                value={values.nickname}
                                                fullWidth={true}
                                                onFocus={e => e.target.select()}
                                                onBlur={handleBlur}
                                                onChange={handleChange} 
                                                label="Nickname"
                                                />
                                        </div>
                                        <div className="col align-self-end text-right text-nowrap pt-2">
                                            <button type="button" className="btn srp-btn btn-lightblue"
                                                disabled={isSubmitting}
                                                onClick={() => this.setState({ ...this.state, showEditNickname: false })}>
                                                Cancel
                                    </button>
                                            <button type="submit" className="btn srp-btn btn-green ml-2" disabled={isSubmitting || values.nickname === accountName}>
                                                {isSubmitting ? <CircularProgress size={20} thickness={5} color="secondary" /> : "Update"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                    : <div className={'pb-4 p-2 ' + rowClass}>
                        <div className="row pt-1">
                            <div className="col-10 d-flex align-self-center" style={{ color: isFinalAccount ? 'rgb(158,42,43)' : 'inherit'}}>
                                <div className="text-truncate">
                                    <SrpTooltip
                                        content={<span>{nickName}</span>}
                                    >
                                        <div className="text-truncate">
                                            <span>{nickName}</span>
                                        </div>
                                    </SrpTooltip>
                                </div>
                            </div>
                            <div className="col-2 d-flex align-self-center">
                                {
                                    alreadyAdded ?
                                    <SrpPopupMenu
                                        content={
                                            <div>
                                                <IconButton color="primary" size="small"
                                                    onClick={() => this.setState({ ...this.state, showEditNickname: true })}
                                                    disabled={disableEditAndDelete} >
                                                    <i className="material-icons">create</i>
                                                </IconButton>
                                                <span>
                                                    <span style={{ borderLeft: '1px solid #888888', fontSize: '20px'}} />
                                                    <IconButton
                                                        disabled={disableEditAndDelete}
                                                        color="primary"
                                                        size="small"
                                                        onClick={this.props.deleteBillAccountOnClick(billAccount, accountName, false)}>
                                                        {disableEditAndDelete ? <CircularProgress className="mr-1" size={20} thickness={5} /> : <i className="material-icons">delete</i>}
                                                    </IconButton>
                                                </span>

                                            </div>
                                            }
                                    >
                                        <IconButton
                                            color="primary"
                                            classes={{
                                                root: "associated-account-more-button-responsive"
                                            }}
                                            size="large">
                                            <i className="material-icons">more_horiz</i>
                                        </IconButton>
                                    </SrpPopupMenu>
                                    : <div
                                        className="text-right profile-summary-subaccount-addaccount"
                                        style={{color: 'rgb(8,126,216)'}}
                                        onClick={() => { this.addAccountToProfile(billAccount, serviceAddress.fullStreetAddress) }}
                                    >
                                        {this.state.disableAddAccount ? <CircularProgress className="mr-1" size={20} thickness={5} /> : 'Add'}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row pt-1 associated-account-row-responsive-account-name" style={{ color: (alreadyAdded ? '' : '#999999')}}>
                            <div className="col-12 align-self-center">
                                {accountText}
                            </div>
                        </div>
                        <div className="row pt-1 associated-account-row-responsive-service-address" style={{ color: (alreadyAdded ? '' : '#999999')}}>
                            <div className="col-12 align-self-center text-truncate">
                                {serviceAddress.fullStreetAddress}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

SummaryBillingSubAccountResponsive.propTypes = {
    subAccount: PropTypes.object.isRequired,
    disableEditAndDelete: PropTypes.bool.isRequired,
    deleteBillAccountOnClick: PropTypes.func.isRequired,
    updateNickname: PropTypes.func.isRequired,
    addAccountToProfile: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => {
    return {
        updateNickname: async (values) => {
            return await dispatch(updateBillAccountNickname(values.billAccount, values.nickname))
        },
        addAccountToProfile: async (billAccount, nickname) => {
            let accountsToAdd = []
            accountsToAdd.push({ billAccount: billAccount, nickname: nickname })

            await dispatch(addMultipleBillAccounts(accountsToAdd))
            await dispatch(getBillAccounts())

            dispatch(addSnackbarMessage(<span>Accounts added</span>))
        }
    }
}

export default connect(null, mapDispatchToProps)(SummaryBillingSubAccountResponsive)